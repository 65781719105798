import { useEffect, useState } from 'react';
import { Cradle } from '@solo/app-core';
import { toast } from 'react-toastify';
import { translate } from '@/app/i18n';
import { Card, Flex, Text } from '@/app/ui';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';

interface SignaturitComponentProps {
  nextStep: () => void;
}

const SignaturitComponent = (props: SignaturitComponentProps) => {
  const { nextStep } = props;
  const [documentURL, setDocumentURL] = useState<string>();
  const { authBloc } = useCradle<Cradle>();

  function handleEvent(event: MessageEvent) {
    if (event.data.event === 'completed') nextStep();
  }

  useEffect(() => {
    async function getSignaturitURL() {
      try {
        const url = await authBloc.getSignaturitTermsAndConditionsURL();
        setDocumentURL(url);
        // eslint-disable-next-line no-console
        console.log(url);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        toast.error('TOAST.SIGNATURIT_ERROR');
      }
    }
    getSignaturitURL();
    window.addEventListener('message', handleEvent);
    return () => window.removeEventListener('message', handleEvent);
  }, []);

  return (
    <Flex.Container style={{ width: '100%' }} justifyContent="flex-start">
      <Flex.Container flexDirection="column" gap="4rem" style={{ width: '100%' }}>
        <Flex.Container style={{ marginLeft: '20px' }}>
          <Text.Bigger style={{ color: Colors.white }}>
            {translate('SIGNATURIT.SIGN_DOC')}
          </Text.Bigger>
        </Flex.Container>
        <Flex.Container alignItems="center" justifyContent="center" alignContent="center">
          <Card.Container backgroundColor={Colors.white} className="p-5">
            <Card.Body style={{ maxWidth: '500px', padding: '3rem' }}>
              <Flex.Container flexDirection="column" gap="2rem">
                <Text.BiggerBold style={{ fontStyle: 'italic' }}>Signaturit</Text.BiggerBold>
                <Text.Bigger>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</Text.Bigger>
              </Flex.Container>
              <iframe
                style={{ width: '100%', height: '500px', border: 'none', marginTop: '1rem' }}
                title="title"
                src={documentURL}
              />
            </Card.Body>
          </Card.Container>
        </Flex.Container>
      </Flex.Container>
    </Flex.Container>
  );
};
export default SignaturitComponent;

import { CreateRecordingProps, Recording, RecordingId, UpdateRecordingProps } from '@solo/app-core';
import { useState } from 'react';
import { translate } from '@/app/i18n';
import { Button, Flex, RouterLink, Text } from '@/app/ui/atoms';
import { DragItem, DropItem, Icon, Modal } from '@/app/ui/molecules';
import { RecordingForm } from '@/app/ui';

interface RecordingTableComponentPorps {
  recordings: Array<Recording>;
  notEditableRecordingsIds?: RecordingId[];
  changeOrder?: (recordingId: string, indexTo: number) => void;
  deleteRecording?: (track: Recording) => void;
  createRecording: (recording: CreateRecordingProps) => void;
  updateRecording: (recording: UpdateRecordingProps, recordingId: RecordingId) => void;
  setNewAudio: (audio: File) => void;
}

const RecordingTableComponent = (props: RecordingTableComponentPorps) => {
  const {
    recordings,
    notEditableRecordingsIds,
    changeOrder,
    deleteRecording,
    createRecording,
    updateRecording,
    setNewAudio,
  } = props;

  const [editRecording, setEditRecording] = useState<Recording>();

  function handleChangeOrder(recordingId: string, indexTo: number) {
    if (changeOrder === undefined || typeof changeOrder !== 'function') return;
    changeOrder(recordingId, indexTo);
  }

  return (
    <Flex.Container flex={1} className="table" flexDirection="column">
      <Flex.Container flex={1} className="thead">
        <Flex.Item flex={1} className="th" />
        <Flex.Item flex={2} className="th">
          {translate('TRACKS.ORDER')}
        </Flex.Item>
        <Flex.Item flex={7} className="th">
          {translate('TRACKS.TRACK_TITLE')}
        </Flex.Item>
        <Flex.Item flex={7} className="th">
          {translate('TRACKS.ARTIST')}
        </Flex.Item>
        <Flex.Item flex={2} className="th" />
      </Flex.Container>
      <Flex.Container className="tbody" flexDirection="column">
        {recordings.map((track, index) => (
          <DropItem
            key={track.id}
            drop={(recordingId) => {
              handleChangeOrder(recordingId, index);
            }}
            accept="ROW"
          >
            <DragItem text={`${track.id}`} type="ROW">
              <Flex.Container className="tr" alignItems="center">
                <Flex.Container className="td" flex={1} alignItems="center">
                  <Icon.DragAndDropIcon
                    width={10}
                    style={{ marginRight: 15, marginLeft: 15, cursor: 'pointer' }}
                  />
                </Flex.Container>
                <Flex.Container className="td" flex={2} alignItems="center">
                  {index + 1}
                </Flex.Container>
                <Flex.Item flex={7} className="td">
                  {notEditableRecordingsIds === undefined ? (
                    <RouterLink $transparent $variant="primary" to={`${track.id}`}>
                      <Text.Paragraph fontWeight={600}>{track.title}</Text.Paragraph>
                    </RouterLink>
                  ) : (
                    <Text.Paragraph fontWeight={600}>{track.title}</Text.Paragraph>
                  )}
                </Flex.Item>
                <Flex.Item flex={7} className="td">
                  {track.artistName}
                </Flex.Item>
                <Flex.Item flex={2} className="td">
                  {!notEditableRecordingsIds?.includes(track.id) && (
                    <Button onClick={() => setEditRecording(track)} $transparent>
                      <Icon.EditIcon width={15} style={{ marginRight: 15 }} />
                    </Button>
                  )}
                  <Button onClick={() => deleteRecording && deleteRecording(track)} $transparent>
                    <Icon.BinIcon width={15} />
                  </Button>
                </Flex.Item>
              </Flex.Container>
            </DragItem>
          </DropItem>
        ))}
      </Flex.Container>
      <Modal
        isOpen={editRecording !== undefined}
        onClose={() => setEditRecording(undefined)}
        closeable
        position="top"
        size="lg"
        backdropCloseable
      >
        <Flex.Container flexDirection="column" gap="40px" padding="30px 90px 20px 90px">
          <RecordingForm
            recordingData={editRecording}
            closeForm={() => setEditRecording(undefined)}
            createRecording={(recording) => createRecording(recording)}
            updateRecording={(recording, recordingId) => updateRecording(recording, recordingId)}
            setNewAudio={(audio) => setNewAudio(audio)}
          />
        </Flex.Container>
      </Modal>
    </Flex.Container>
  );
};

export default RecordingTableComponent;

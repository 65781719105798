import { Flex, Text, Section } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import MusicalReleaseForm from '@/app/ui/organisms/MusicalReleaseForm/MusicalReleaseForm';

const AddMusicalRelease = () => (
  <>
    <Section py={15}>
      <Flex.Container padding="0px 50px">
        <Text.PageTitle color={Colors.purple}>
          {translate('WORKS_PAGES.MY_RELEASES')}{' '}
        </Text.PageTitle>
        <Text.Title3>&nbsp;/ {translate('BUTTON.ADD_RELEASE')}</Text.Title3>
      </Flex.Container>
    </Section>

    <MusicalReleaseForm />
  </>
);

export default AddMusicalRelease;

import { Cradle, Distribution, MusicalRelease, UpdateMusicalReleaseProps } from '@solo/app-core';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
// TODO fix export on CORE
import { FileId } from '@solo/app-core/dist/modules/common/domain/FileEntity';
import { translate } from '@/app/i18n';
import { Flex, Img, Button, Section, Text } from '@/app/ui/atoms';
import loginImg from '@/app/assets/images/login-img.png';
import { Modal } from '@/app/ui/molecules';
import DataInfo from '@/app/ui/organisms/DataInfo/DataInfo';
import { useCradle } from '@/app/contexts';
import MusicalReleaseDetailsForm, {
  genres,
  labels,
  languages,
} from '@/app/ui/organisms/MusicalReleaseDetailsForm/MusicalReleaseDetailsForm';
import MusicalReleaseDistributionForm, {
  MusicalReleaseDistributionData,
} from '@/app/ui/organisms/MusicalReleaseDistributionForm/MusicalReleaseDistributionForm';
import { convertArrayToString } from '@/app/utils/ArrayToString';
import { LoadingComponent } from '@/app/ui';
import { useBlocState } from '@/app/hooks';

const MusicalReleaseDataComponent = () => {
  const { id } = useParams();
  const [editDetails, setEditDetails] = useState<boolean>(false);
  const [editDistribution, setEditDistribution] = useState<boolean>(false);
  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);

  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionBlocState = useBlocState(digitalDistributionBloc);
  const [digitalDistributions, setDigitalDistributions] = useState<Distribution[]>([]);

  const [musicalRelease, setMusicalRelease] = useState<MusicalRelease>();
  // const [type, setType] = useState<string>();
  const [artwork, setArtwork] = useState<File>();

  useEffect(() => {
    const getMusicalRelease = async () => {
      if (id === undefined) {
        return;
      }
      const w = await musicalWorksBloc.getMusicalRelease(id);
      if (w === undefined) {
        return;
      }
      setMusicalRelease(w);
    };
    async function loadDistribution() {
      if (digitalDistributionBlocState.kind !== 'LoadedDigitalDistributionState' || !id) return;
      const distributions = await digitalDistributionBloc.getDistributions({
        musicalReleaseId: id,
      });
      setDigitalDistributions(distributions);
    }
    getMusicalRelease();
    loadDistribution();
  }, [musicalWorksState]);

  async function saveDetails(data: UpdateMusicalReleaseProps) {
    if (!id) return;
    let resourceId: FileId | undefined;
    if (artwork) {
      try {
        resourceId = await musicalWorksBloc.uploadArtwork(artwork);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
    }
    let musicalReleaseResponse: MusicalRelease | undefined;
    try {
      const updateData = resourceId ? { ...data, resourceId } : data;
      musicalReleaseResponse = await musicalWorksBloc.updateMusicalRelease(id, updateData);
      toast.success(translate('TOAST.CHANGES_SAVED'));
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }
    if (!musicalReleaseResponse) return;
    setMusicalRelease(musicalReleaseResponse);
    musicalWorksBloc.loadMusicalReleases();
  }

  async function saveDistributions(distribution: MusicalReleaseDistributionData) {
    if (!id) return;
    distribution.newDistributions.forEach((dist) =>
      digitalDistributionBloc.createDistribution(id, dist),
    );
    distribution.updatedDistributions.forEach((dist) =>
      digitalDistributionBloc.updateDistribution(dist.distributionId, dist.props),
    );
    distribution.deletedDistributions.forEach((distId) => {
      digitalDistributionBloc.deleteDistribution(distId);
    });
    musicalWorksBloc.loadMusicalReleases();
  }

  // useEffect(() => {
  //   switch (musicalRelease?.type) {
  //     case 'ALBUM':
  //       setType(translate('WORKS_PAGES.ALBUM_DETAILS'));
  //       break;
  //     case 'SINGLE':
  //       setType(translate('WORKS.SINGLE_DETAILS'));
  //       break;
  //     default:
  //       setType(translate('WORKS.EP_DETAILS'));
  //   }
  // }, [musicalRelease]);

  if (musicalRelease === undefined) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Section style={{ alignItems: 'end', paddingBottom: 0, marginBottom: 32 }}>
        <Text.SectionTitle>{translate('WORKS_PAGES.MUSICAL_RELEASE')}</Text.SectionTitle>
        <Button
          $size="md"
          $transparent
          $variant="primary"
          onClick={() => setEditDetails(true)}
          style={{ padding: '0 0 1px' }}
        >
          <Text.Span fontWeight={600} style={{ marginLeft: 20 }}>
            {translate('WORKS_PAGES.EDIT_DETAILS')}
          </Text.Span>
        </Button>
      </Section>
      <Modal
        isOpen={editDetails}
        onClose={() => setEditDetails(false)}
        closeable
        position="top"
        size="lg"
        backdropCloseable
      >
        <Flex.Container flexDirection="column" gap="40px" padding="30px 90px 20px 90px">
          <MusicalReleaseDetailsForm
            musicalRelease={musicalRelease}
            closeModal={() => setEditDetails(false)}
            saveDetails={(data) => saveDetails(data)}
            setNewArtwork={(artworkFile) => setArtwork(artworkFile)}
          />
        </Flex.Container>
      </Modal>
      {id && (
        <Modal
          isOpen={editDistribution}
          onClose={() => setEditDistribution(false)}
          closeable
          position="top"
          size="lg"
          backdropCloseable
        >
          <Flex.Container flexDirection="column" gap="40px" padding="30px 90px 20px 90px">
            <MusicalReleaseDistributionForm
              musicalReleaseId={id}
              distributionData={digitalDistributions}
              closeModal={() => setEditDistribution(false)}
              saveDistributions={(distribution) => saveDistributions(distribution)}
            />
          </Flex.Container>
        </Modal>
      )}
      <Flex.Container gap="1rem">
        <Flex.Item flex={1}>
          <Flex.Container>
            <Img
              src={musicalRelease.resourceId ? musicalRelease.resourceId : loginImg}
              style={{ aspectRatio: '1 / 1', width: '100%' }}
            />
          </Flex.Container>
        </Flex.Item>
        <Flex.Item flex={2} style={{ paddingLeft: 20, paddingRight: 20 }}>
          <DataInfo label={translate('WORKS.ALBUM_TITLE')} value={musicalRelease?.name || ''} />
          <Flex.Container>
            <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.MAIN_ARTIST')}
                value={
                  musicalRelease.contributors && musicalRelease.contributors.length > 0
                    ? convertArrayToString(
                        [musicalRelease.artistName].concat(
                          musicalRelease.contributors?.map((artist) => artist.artist.name),
                        ),
                      )
                    : musicalRelease.artistName
                }
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.LANGUAGE')}
                value={languages[musicalRelease.languageId] || '-'}
                infoTooltip="Lorem ipsum dolor secit"
              />
            </Flex.Item>
          </Flex.Container>
          <Flex.Container>
            <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.RECORD_LABEL')}
                value={musicalRelease.labelId !== undefined ? labels[musicalRelease.labelId] : '-'}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <DataInfo label={translate('WORKS.UPC_EAN')} value={musicalRelease.upc || '-'} />
            </Flex.Item>
          </Flex.Container>
          <Flex.Container>
            <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.RELEASE_DATE')}
                value={musicalRelease.releaseDate || '-'}
              />
            </Flex.Item>
            {/* <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.RELEASE_TIME')}
                value={musicalRelease.releaseTime || '-'}
              />
            </Flex.Item> */}
          </Flex.Container>
          <Flex.Container>
            <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.PRIMARY_GENRE')}
                value={genres[musicalRelease.primaryMusicStyleId] || '-'}
              />
            </Flex.Item>
            {/* <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.SECONDARY_GENRE')}
                value={musicalRelease.secondaryGenre || '-'}
              />
            </Flex.Item> */}
          </Flex.Container>
          <Flex.Container>
            <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.PRODUCT_COPYRIGHT')}
                value={musicalRelease.copyrightP || '-'}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <DataInfo
                label={translate('WORKS.SOUND_RECORDING_COPYRIGHT')}
                value={musicalRelease.copyrightC || '-'}
              />
            </Flex.Item>
          </Flex.Container>
        </Flex.Item>
      </Flex.Container>
      {/* <Section style={{ alignItems: 'end', paddingBottom: 0, marginBottom: 10 }}>
        <Text.SectionTitle>{translate('WORKS_PAGES.TERRITORY_RESTRICTIONS')}</Text.SectionTitle>
        <Button
          $size="md"
          $transparent
          $variant="primary"
          onClick={() => setEditDistribution(true)}
          style={{ padding: 0 }}
        >
          <Text.Span fontWeight={600} style={{ marginLeft: 20 }}>
            {translate('WORKS_PAGES.CHANGE_RESTRICTION')}
          </Text.Span>
        </Button>
      </Section>
      <Flex.Container alignItems="center">
        <Icon.AlertCircleIcon style={{ marginRight: 5 }} width={20} />
        <Text.Paragraph>{translate('WORKS_PAGES.CHANGE_RESTRICTION_WARNING')}</Text.Paragraph>
      </Flex.Container>
       <Flex.Container gap="1rem">
        {digitalDistributions[0]?.territoryRestrictions.length > 0 ? (
          digitalDistributions[0].territoryRestrictions.map((dist) => (
            <Flex.Container gap="1rem" alignItems="center" key={dist.territory.tis.toString()}>
              {dist.mod === TerritoryRestrictionModificator.INCLUDE ? (
                <Icon.CheckIcon />
              ) : (
                <Icon.Forbidden />
              )}
              <Text.LabelMedium>{dist.territory.name}</Text.LabelMedium>
            </Flex.Container>
          ))
        ) : (
          <Flex.Container gap="1rem" alignItems="center">
            <Icon.CheckIcon />
            <Text.LabelMedium>{translate('WORKS_PAGES.ALL_TERRITORIES')}</Text.LabelMedium>
          </Flex.Container>
        )}
      </Flex.Container> */}
    </>
  );
};

export default MusicalReleaseDataComponent;

import { useParams } from 'react-router-dom';
import { Cradle, Recording, RecordingId } from '@solo/app-core';
import { useState, useEffect } from 'react';
import { Button, Flex, RouterLink, Section, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import DataInfo from '@/app/ui/organisms/DataInfo/DataInfo';
import TooltipInfo from '@/app/ui/organisms/TooltipInfo';
import { useCradle } from '@/app/contexts';
import { LoadingComponent } from '@/app/ui';
import { useBlocState } from '@/app/hooks';
import { languages } from '@/app/ui/organisms/MusicalReleaseDetailsForm/MusicalReleaseDetailsForm';
import { roles } from '@/app/ui/organisms/RecordingForm/RecordingForm';

const TrackDataComponent = () => {
  const { id, trackId } = useParams();
  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);
  const [track, setTrack] = useState<Recording>();
  const [totalMech, setTotalMech] = useState<number>();
  const [originalAuthors, setOriginalAuthors] = useState<(string | undefined)[]>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getRecording = async () => {
      if (id === undefined || trackId === undefined) {
        return;
      }
      const t = await musicalWorksBloc.getRecording(trackId as RecordingId);
      if (t === undefined) {
        return;
      }
      setTrack(t);

      if (t.type === 'ORIGINAL') {
        const { authors } = t;
        setTotalMech(
          authors
            .map((author) => author.mechanicalSplit || 0)
            .reduce((previous, current) => previous + current, 0),
        );
      }
      if (t.authors) setOriginalAuthors(t.authors.map((au) => au.authorComposerName));
    };

    getRecording();
  }, []);

  useEffect(() => {
    if (musicalWorksState.kind !== 'LoadedMusicalWorksState') {
      setLoading(true);
      return;
    }
    setLoading(false);
  }, [musicalWorksState]);

  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <>
      <Section style={{ marginBottom: 24 }}>
        <RouterLink $transparent $variant="primary" to={`/works/${id}/tracks`}>
          <Icon.ArrowLeft width={10} />
          <Text.Paragraph fontWeight={600} style={{ marginLeft: 5 }}>
            {translate('WORKS_PAGES.BACK_TO_TRACKS')}
          </Text.Paragraph>
        </RouterLink>
      </Section>
      <Section style={{ alignItems: 'end', paddingBottom: 0, marginBottom: 32 }}>
        <Text.SectionTitle>{translate('WORKS_PAGES.TRACK_DETAILS')}</Text.SectionTitle>
        <Button
          $size="md"
          $transparent
          $variant="primary"
          // onClick={() => setEdit(true)}
          style={{ padding: 0 }}
        >
          <Text.Span fontWeight={600} style={{ marginLeft: 20 }}>
            {translate('WORKS_PAGES.EDIT_DETAILS')}
          </Text.Span>
        </Button>
      </Section>
      <Flex.Container flexDirection="column">
        <DataInfo label={translate('TRACKS.TRACK_TITLE')} value={track?.title || ''} />
        <Flex.Container>
          {/* <Flex.Item flex={1}>
            <DataInfo
              label={translate('TRACKS.ALTERNATIVE_TITLE')}
              value={track?.alternativeTitle || ''}
              infoTooltip="Lorem ipsum dolor secit"
            />
          </Flex.Item> */}
          <Flex.Item flex={1}>
            <DataInfo label={translate('TRACKS.COMPOSITION_TYPE')} value={track?.type || ''} />
          </Flex.Item>
        </Flex.Container>
        <Flex.Container>
          <Flex.Item flex={1}>
            <DataInfo
              label={translate('TRACKS.LANGUAGE_LYRICS')}
              value={track?.languageId ? languages[track.languageId] : ''}
              infoTooltip="Lorem ipsum dolor secit"
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <DataInfo
              label={translate('TRACKS.EXPLICIT_CONTENT')}
              infoTooltip="Lorem ipsum dolor secit"
              value={track?.explicitContent ? translate('TRACKS.YES') : translate('TRACKS.NO')}
            />
          </Flex.Item>
        </Flex.Container>
        <DataInfo
          label={translate('TRACKS.ISRC')}
          infoTooltip="Lorem ipsum dolor secit"
          value={track?.isrc || ''}
        />
        <DataInfo
          label={translate('TRACKS.AUDIO_URL')}
          infoTooltip="Lorem ipsum dolor secit"
          value={track?.audioId || ''}
        />
        <DataInfo
          label={translate('TRACKS.LYRICS')}
          value={track?.lyrics || ''}
          style={{ width: 200 }}
        />
        <Text.Paragraph color={Colors.primary} fontWeight={600}>
          {translate('WORKS_PAGES.SEE_MORE')}
        </Text.Paragraph>
      </Flex.Container>

      <Section style={{ alignItems: 'end', paddingBottom: 0, marginTop: 32, marginBottom: 12 }}>
        <Button
          $size="md"
          $transparent
          $variant="primary"
          // onClick={() => setEdit(true)}
          style={{ padding: 0 }}
        >
          <Text.Span fontWeight={600} style={{ marginLeft: 20 }}>
            {translate('WORKS_PAGES.EDIT_DETAILS')}
          </Text.Span>
        </Button>
      </Section>
      <Flex.Item flex={1}>
        <DataInfo label={translate('TRACKS.ARTIST')} value={track?.artistName || ''} />
      </Flex.Item>
      <Section style={{ alignItems: 'end', paddingBottom: 0, marginTop: 32, marginBottom: 12 }}>
        <Flex.Container>
          <Text.SectionTitle>{translate('WORKS_PAGES.AUTHOR_COMPOSERS')}</Text.SectionTitle>
          <TooltipInfo text="Lorem ipsum dolor secit" />
        </Flex.Container>
        <Button $size="md" $transparent $variant="primary" style={{ padding: 0 }}>
          <Text.Span fontWeight={600} style={{ marginLeft: 20 }}>
            {translate('WORKS_PAGES.EDIT_DETAILS')}
          </Text.Span>
        </Button>
      </Section>
      {track?.type === 'ORIGINAL'
        ? track.authors?.map((authorWithComposer) => (
            <Flex.Container key={authorWithComposer.rightsId}>
              <Flex.Item flex={2}>
                <DataInfo
                  label={translate('TRACKS.ARTIST')}
                  value={authorWithComposer.authorComposerName || ''}
                />
              </Flex.Item>
              <Flex.Item flex={2}>
                <DataInfo
                  label={translate('TRACKS.ROLE')}
                  value={roles[authorWithComposer.roleId]}
                />
              </Flex.Item>
              <Flex.Item flex={1}>
                <DataInfo
                  label={translate('TRACKS.MECH')}
                  value={`${authorWithComposer.mechanicalSplit} %`}
                />
              </Flex.Item>
            </Flex.Container>
          ))
        : originalAuthors?.map((author) => {
            if (author)
              return (
                <Flex.Container key={author}>
                  <Flex.Item flex={2}>
                    <DataInfo label={translate('TRACKS.ARTIST')} value={author} />
                  </Flex.Item>
                </Flex.Container>
              );
            return undefined;
          })}
      <Flex.Container>
        <Flex.Item flex={2} />
        <Flex.Item flex={2}>
          <Text.Label fontWeight={600} fontSize={16}>
            {translate('WORKS_PAGES.TOTAL')}
          </Text.Label>
        </Flex.Item>
        <Flex.Item flex={1}>
          <Text.Label fontWeight={600} fontSize={16}>{`${totalMech} %`}</Text.Label>
        </Flex.Item>
      </Flex.Container>
    </>
  );
};

export default TrackDataComponent;

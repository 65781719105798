import { AuthorComposer } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Input, Text } from '@/app/ui/atoms';
import { Icon, InputTextAutocomplete, NumericInputWithLabel } from '@/app/ui/molecules';

interface AuthorsComposersTableComponentProps {
  recordingAuthors: Array<AuthorComposer>;
  deleteRecordingAuthor: (authorComposer: AuthorComposer) => void;
  saveRecordingAuthor: (recordingAuthor: AuthorComposer) => void;
}

const roles = ['Main', 'Composer', 'Editor'];

const AuthorsComposersTableComponent = ({
  recordingAuthors,
  deleteRecordingAuthor,
  saveRecordingAuthor,
}: AuthorsComposersTableComponentProps) => (
  <Flex.Container className="table" flexDirection="column">
    <Flex.Container>
      <Flex.Container flex={1} gap="1rem">
        <Flex.Item flex={10} className="th">
          <Text.LabelSmall>{translate('WORKS_PAGES.AUTHOR_COMPOSERS')}</Text.LabelSmall>
        </Flex.Item>
        <Flex.Item flex={4} className="th">
          <Text.LabelSmall>{translate('WORKS_PAGES.COMPOSER_ID')}</Text.LabelSmall>
        </Flex.Item>
        <Flex.Item flex={8} className="th">
          <Text.LabelSmall>{translate('TRACKS.ROLE')}</Text.LabelSmall>
        </Flex.Item>
        {/* <Flex.Item flex={2} className="th">
          <Text.LabelSmall>{translate('TRACKS.PERF')}</Text.LabelSmall>
        </Flex.Item> */}
        <Flex.Item flex={2} className="th">
          <Text.LabelSmall>{translate('TRACKS.MECH')}</Text.LabelSmall>
        </Flex.Item>
        <Flex.Item flex={1} className="th" />
      </Flex.Container>
    </Flex.Container>
    <Flex.Container flexDirection="column" gap="10px">
      {recordingAuthors.map((recordingAuthor) => (
        <Flex.Container key={recordingAuthor.authorComposerId} className="tr" alignItems="center">
          <Flex.Container flex={1} gap="1rem">
            <Flex.Container className="td" flex={10} alignItems="center">
              <Input.Text value={recordingAuthor.authorComposerName} disabled />
            </Flex.Container>
            <Flex.Container className="td" flex={4} alignItems="center">
              <Input.Text value={recordingAuthor.authorComposerId} disabled />
            </Flex.Container>
            <Flex.Container flex={8}>
              <InputTextAutocomplete
                disabled
                placeholder={translate('WORKS_PAGES.TYPES_OF_ROLE')}
                items={Array.from(roles, (role: string) => ({
                  value: role,
                  label: role,
                }))}
                onChange={(role) =>
                  saveRecordingAuthor({
                    ...recordingAuthor,
                    roleId: parseInt(role, 10),
                  })
                }
                defaultOption={
                  recordingAuthor.roleId !== undefined
                    ? {
                        value: recordingAuthor.roleId.toString(),
                        label: roles[recordingAuthor.roleId % roles.length], // TODO back
                      }
                    : undefined
                }
              />
            </Flex.Container>
            {/* <Flex.Container flex={2}>
              <NumericInputWithLabel
                placeholder="100%"
                value={recordingAuthor.performanceSplit}
                onChange={(performanceSplit) =>
                  saveRecordingAuthor({
                    ...recordingAuthor,
                    performanceSplit,
                  })
                }
              />
            </Flex.Container> */}
            <Flex.Container flex={2}>
              <NumericInputWithLabel
                disabled
                value={recordingAuthor.mechanicalSplit}
                placeholder="100%"
                onChange={(mechanicalSplit) =>
                  saveRecordingAuthor({
                    ...recordingAuthor,
                    mechanicalSplit,
                  })
                }
              />
            </Flex.Container>

            <Flex.Item flex={1} className="td" style={{ marginTop: '1rem' }}>
              <Button
                onClick={() => deleteRecordingAuthor(recordingAuthor)}
                style={{ marginLeft: '10px' }}
                $transparent
              >
                <Icon.BinIcon width={15} />
              </Button>
            </Flex.Item>
          </Flex.Container>
        </Flex.Container>
      ))}
    </Flex.Container>
    <Flex.Container className="tr" alignItems="center" style={{ marginTop: '20px' }}>
      <Flex.Container flex={1} justifyContent="flex-end">
        <Flex.Item flex={19} className="td" />
        <Flex.Item flex={3} className="td">
          <Text.SectionSubtitleBold>Total</Text.SectionSubtitleBold>
        </Flex.Item>
        {/* <Flex.Item flex={2} className="td">
          <Text.SectionSubtitleBold>
            {recordingAuthors
              .map((recordingAuthor) => recordingAuthor.performanceSplit)
              .reduce((sum, element) => sum || 0 + (element || 0), 0)}
            %
          </Text.SectionSubtitleBold>
        </Flex.Item> */}
        <Flex.Item flex={2} className="td">
          <Text.SectionSubtitleBold>
            {recordingAuthors
              .map((recordingAuthor) => recordingAuthor.mechanicalSplit)
              .reduce((sum, element) => sum || 0 + (element || 0), 0)}
            %
          </Text.SectionSubtitleBold>
        </Flex.Item>
        <Flex.Item flex={1} className="td" />
      </Flex.Container>
    </Flex.Container>
  </Flex.Container>
);

export default AuthorsComposersTableComponent;

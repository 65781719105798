import { Outlet } from 'react-router-dom';
import Colors from '@/app/styles/Colors';
import { Flex, Main } from '@/app/ui/atoms';
import { NavBar, SideBar, UserDropDown } from '@/app/ui/organisms';

const DashboardLayout = () => (
  <Flex.Container
    style={{
      backgroundColor: Colors.white,
      color: Colors.black,
      overflowX: 'hidden',
      height: '100vh',
    }}
  >
    <SideBar>
      <NavBar />
    </SideBar>
    <UserDropDown />
    <Main>
      <Outlet />
    </Main>
  </Flex.Container>
);

export default DashboardLayout;

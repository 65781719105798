import { Navigate, useLocation } from 'react-router-dom';
import { AuthUser, Cradle } from '@solo/app-core';
import { useState, useEffect } from 'react';
import { useCradle } from '@/app/contexts';
import Icons from '@/app/ui/molecules/Icons';

const RequireAuth = ({ children }: { children: JSX.Element }) => {
  const { authBloc } = useCradle<Cradle>();
  const location = useLocation();
  const [user, setUser] = useState<AuthUser | undefined>();
  const [checkedUser, setCheckedUser] = useState<boolean>(false);

  useEffect(() => {
    async function getCurrentUser() {
      try {
        const currentUser = await authBloc.getCurrentUser();
        setUser(currentUser);
      } catch (error) {
        setUser(undefined);
      }
      setCheckedUser(true);
    }
    getCurrentUser();
  }, []);

  if (!checkedUser) return <Icons.Spinner />;

  if (!user) {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }

  return children;
};

export default RequireAuth;

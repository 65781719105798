import { useEffect, useState } from 'react';
import { Cradle, DigitalPlatformId } from '@solo/app-core';
import { Flex, Text } from '@/app/ui/atoms';
import { CustomBarChart, InputTextAutocomplete } from '@/app/ui';
// import { platforms } from '@/app/ui/organisms/Plays/PlaysByPlatform';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';
import { BarData } from '@/app/ui/organisms/CustomBarChart/CustomBarChart';
import { useBlocState } from '@/app/hooks';
import { getDateFrom, spanFilters, TimeSpan } from '@/app/utils/GetDateFrom';
import { translate } from '@/app/i18n';
import { stringToInt } from '@/app/utils/StringToInt';

export type UsageData = {
  [key: string]: number;
};

const TopSongsPlatform = () => {
  const [currentFromDate, setCurrentFromDate] = useState<Date>(getDateFrom(TimeSpan.lastYear));
  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const [usagesData, setUsagesData] = useState<BarData[][]>([]);
  const [currentGroupFilter, setCurrentGroupFilter] = useState<DigitalPlatformId | undefined>();

  const { consumptionReportsBloc } = useCradle<Cradle>();

  const digitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  useEffect(() => {
    async function getTopSongsData() {
      const usagesFiltered = await consumptionReportsBloc.getUsages({
        digitalPlatformId: currentGroupFilter,
        dateFrom: currentFromDate || undefined,
      });

      const usages: UsageData = {};
      usagesFiltered.data.forEach((usage) => {
        if (usages[usage.id] === undefined) usages[usage.id] = usage.plays;
        usages[usage.id] += usage.plays;
      });

      const usagesBarData: BarData[] = [];
      Object.keys(usages).forEach((usage: string) => {
        usagesBarData.push({
          x: usagesFiltered.data.find((u) => u.id === usage)?.recording?.title || '',
          y: usages[usage],
        });
      });

      const topSongs = usagesBarData
        .sort((usage1, usage2) => {
          if (usage1.y < usage2.y) return 1;
          if (usage2.y < usage1.y) return -1;
          return 0;
        })
        .slice(0, 6);

      const topSongsRigged = topSongs.map((topS) => ({
        ...topS,
        y: topS.y - Math.floor(Math.random() * topS.y) + topS.y / 2,
      }));

      setUsagesData([topSongsRigged]);
    }
    getTopSongsData();
  }, [currentGroupFilter, currentFromDate]);

  return (
    <Flex.Container flexDirection="column" className="mt-5" gap="2.5rem">
      <Flex.Container style={{ width: '41%' }} gap="1rem">
        <InputTextAutocomplete
          placeholder="Platform..."
          items={[{ value: 'All', label: 'All' }].concat(
            digitalPlatforms.map((p) => ({ value: p.id.toString(), label: p.name })),
          )}
          onChange={(groupFilter) =>
            groupFilter === 'All'
              ? setCurrentGroupFilter(undefined)
              : setCurrentGroupFilter(stringToInt(groupFilter))
          }
        />
        <InputTextAutocomplete
          items={spanFilters}
          onChange={(filter) => setCurrentFromDate(getDateFrom(filter as TimeSpan))}
          isSearchable={false}
        />
      </Flex.Container>
      {usagesData[0] && usagesData[0].length > 0 ? (
        <CustomBarChart yaxis="Plays" data={usagesData} barColors={[Colors.primary]} />
      ) : (
        <Flex.Container justifyContent="center">
          <Text.LabelMedium style={{ paddingTop: '3px' }}>
            {translate('USAGES.NO_PLAYS_TO_SHOW')}
          </Text.LabelMedium>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};

export default TopSongsPlatform;

import { useState } from 'react';
import { toast } from 'react-toastify';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Button, Card, Flex, Text, InputWithLabel, RouterLink } from '@/app/ui';
import { CreateUserProps } from '@/app/pages/SignUpPage/SignUpPage';

interface SignUpComponentProps {
  nextStep: () => void;
  saveUserSignUpInfo: (props: CreateUserProps) => void;
}

const SignUpComponent = (props: SignUpComponentProps) => {
  const { nextStep, saveUserSignUpInfo } = props;

  const [signUpInfo, setSignUpInfo] = useState<CreateUserProps>({
    email: '',
    firstName: '',
    surname: undefined,
    password: undefined,
    repeatPassword: undefined,
  });

  const [fieldsMissing, setFieldsMissing] = useState<boolean>(false);

  function saveUserInfo() {
    const missing = Object.values(signUpInfo).some((field) => field === undefined);

    if (missing) {
      setFieldsMissing(true);
      toast.error(translate('TOAST.ALL_MANDATORY_ERROR'));
      return;
    }

    if (signUpInfo.password !== signUpInfo.repeatPassword) {
      toast.error(translate('TOAST.PASSWORD_ERROR'));
      setSignUpInfo({ ...signUpInfo, password: undefined, repeatPassword: undefined });
      setFieldsMissing(true);
      return;
    }
    saveUserSignUpInfo(signUpInfo);
    nextStep();
  }

  return (
    <Card.Container
      backgroundColor={Colors.blackWithOpacity}
      className="p-5"
      style={{ minWidth: '450px' }}
    >
      <Card.Body style={{ paddingBottom: 0 }}>
        <Flex.Container flexDirection="column" gap="1.8rem">
          <InputWithLabel
            labelColor={Colors.white}
            label={translate('FORMS.NAME')}
            mandatoryError={fieldsMissing && signUpInfo?.firstName === undefined}
            onChange={(firstName) => setSignUpInfo({ ...signUpInfo, firstName })}
          />
          <InputWithLabel
            labelColor={Colors.white}
            label={translate('FORMS.SURNAME')}
            mandatoryError={fieldsMissing && signUpInfo?.surname === undefined}
            onChange={(surname) => setSignUpInfo({ ...signUpInfo, surname })}
          />
          <InputWithLabel
            labelColor={Colors.white}
            label={translate('FORMS.EMAIL')}
            mandatoryError={fieldsMissing && signUpInfo?.email === undefined}
            onChange={(email) => setSignUpInfo({ ...signUpInfo, email })}
          />
          <InputWithLabel
            labelColor={Colors.white}
            label={translate('FORMS.PASSWORD')}
            type="password"
            value={signUpInfo.password}
            mandatoryError={fieldsMissing && signUpInfo?.password === undefined}
            onChange={(password) => setSignUpInfo({ ...signUpInfo, password })}
          />
          <InputWithLabel
            labelColor={Colors.white}
            label={translate('FORMS.REPEAT_PASSWORD')}
            type="password"
            value={signUpInfo.repeatPassword}
            mandatoryError={fieldsMissing && signUpInfo?.repeatPassword === undefined}
            onChange={(repeatPassword) => setSignUpInfo({ ...signUpInfo, repeatPassword })}
          />
          <Button onClick={() => saveUserInfo()}>
            <Text.Paragraph fontSize={14} style={{ color: Colors.white }}>
              {translate('LOGIN.SIGN_UP')}
            </Text.Paragraph>
          </Button>
        </Flex.Container>
        <Flex.Container className="mt-5" gap="1rem" justifyContent="center">
          <Text.Paragraph color={Colors.borderInput} fontSize={14}>
            {translate('SIGN_UP.ALREADY_ACCOUNT')}
          </Text.Paragraph>
          <RouterLink $transparent to="/auth/login">
            <Text.Paragraph fontSize={14} style={{ color: Colors.purple }}>
              {translate('LOGIN.LOG_IN')}
            </Text.Paragraph>
          </RouterLink>
        </Flex.Container>
      </Card.Body>
    </Card.Container>
  );
};
export default SignUpComponent;

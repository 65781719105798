import { useEffect, useState } from 'react';
import { Cradle } from '@solo/app-core';
import { DigitalDistributionIcons, Flex, InputTextAutocomplete, Text } from '@/app/ui';
import { translate } from '@/app/i18n';
import ProgressBar from '@/app/ui/atoms/ProgressBar';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';
import { getDateFrom, spanFilters, TimeSpan } from '@/app/utils/GetDateFrom';
import { UsageData } from '@/app/ui/organisms/Usages/TopSongsPlatform';

interface PlatformPlays {
  name: string;
  plays: number;
}

const PlaysByPlatform = () => {
  const [currentFromDate, setCurrentFromDate] = useState<Date>(getDateFrom(TimeSpan.lastYear));
  const [topPlatforms, setTopPlatforms] = useState<PlatformPlays[]>();

  const { consumptionReportsBloc } = useCradle<Cradle>();

  useEffect(() => {
    async function getTopSongsData() {
      const usagesFiltered = await consumptionReportsBloc.getUsages({
        dateFrom: currentFromDate || undefined,
      });

      const usages: UsageData = {};
      usagesFiltered.data.forEach((usage) => {
        if (usages[usage.digitalPlatform?.name || usage.digitalPlatformId] === undefined)
          usages[usage.digitalPlatform?.name || usage.digitalPlatformId] = usage.plays;
        usages[usage.digitalPlatform?.name || usage.digitalPlatformId] += usage.plays;
      });

      const playsByPlatform: PlatformPlays[] = [];
      Object.keys(usages).forEach((usage: string) => {
        playsByPlatform.push({
          name:
            usagesFiltered.data.find((u) => u.digitalPlatform?.name === usage)?.digitalPlatform
              ?.name || '',
          plays: usages[usage],
        });
      });

      const topSongs = playsByPlatform
        .sort((usage1, usage2) => {
          if (usage1.plays < usage2.plays) return 1;
          if (usage2.plays < usage1.plays) return -1;
          return 0;
        })
        .slice(0, 6);

      setTopPlatforms(topSongs);
    }
    getTopSongsData();
  }, [currentFromDate]);

  return (
    <Flex.Container className="mt-5" flexDirection="column" gap="4rem">
      <Flex.Container style={{ width: '20%' }}>
        <InputTextAutocomplete
          items={spanFilters}
          onChange={(filter) => setCurrentFromDate(getDateFrom(filter as TimeSpan))}
          isSearchable={false}
        />
      </Flex.Container>
      <Flex.Container flexWrap="wrap" gap="1rem 3rem">
        {topPlatforms && topPlatforms.length > 0 ? (
          topPlatforms?.map((platform) => (
            <Flex.Container
              gap="1rem"
              key={platform.name}
              alignItems="center"
              padding="0 0 0.5rem"
              style={{ width: '48%', borderBottom: `1px solid ${Colors.borderInput}` }}
            >
              <Flex.Container gap="1rem">
                <DigitalDistributionIcons width={20} icon={platform ? platform.name : ''} />
                <Text.LabelMedium style={{ paddingTop: '3px' }}>{platform.name}</Text.LabelMedium>
              </Flex.Container>
              <Flex.Container
                className="mb-2"
                style={{ width: '50%', marginLeft: 'auto' }}
                alignItems="flex-end"
                flexDirection="column"
              >
                <Text.Anotation>
                  {platform.plays} {translate('USAGES.PLAYS_LOWER_CASE')}
                </Text.Anotation>
                <ProgressBar
                  progress={(platform.plays / Math.max(...topPlatforms.map((p) => p.plays))) * 100}
                />
              </Flex.Container>
            </Flex.Container>
          ))
        ) : (
          <Flex.Container justifyContent="center">
            <Text.LabelMedium style={{ paddingTop: '3px' }}>
              {translate('USAGES.NO_PLAYS_TO_SHOW')}
            </Text.LabelMedium>
          </Flex.Container>
        )}
      </Flex.Container>
    </Flex.Container>
  );
};

export default PlaysByPlatform;

import { DigitalPlatform } from '@solo/app-core';
import { DigitalDistributionIcons, Flex, Text } from '@/app/ui';
import { translate } from '@/app/i18n';
import ProgressBar from '@/app/ui/atoms/ProgressBar';
import Colors from '@/app/styles/Colors';

export type QuantityPerItemObject = {
  name: string;
  quantity: number;
};

interface QuantityPerItemProps {
  items: QuantityPerItemObject[];
  flexDirection?: 'row' | 'column';
  platform?: DigitalPlatform;
}

const QuantityPerItemList = (props: QuantityPerItemProps) => {
  const { items, platform, flexDirection } = props;

  return (
    <Flex.Container flexDirection={flexDirection || 'row'} style={{ width: '100%' }} gap="1.5rem">
      {items.length > 0 ? (
        items.map((item) => (
          <Flex.Container
            key={`${item.name}${item.quantity}`}
            gap="1rem"
            alignItems="center"
            padding="0 0 0.5rem"
            style={{ borderBottom: `1px solid ${Colors.borderInput}` }}
          >
            <Flex.Container gap="1rem">
              <DigitalDistributionIcons width={20} icon={platform ? platform.name : ''} />
              <Text.LabelMedium style={{ paddingTop: '3px' }}>{item.name}</Text.LabelMedium>
            </Flex.Container>
            <Flex.Container
              className="mb-2"
              style={{ width: '50%', marginLeft: 'auto' }}
              alignItems="flex-end"
              flexDirection="column"
            >
              <Text.Anotation>
                {item.quantity} {translate('USAGES.PLAYS_LOWER_CASE')}
              </Text.Anotation>
              <ProgressBar
                progress={(item.quantity / Math.max(...items.map((p) => p.quantity))) * 100}
              />
            </Flex.Container>
          </Flex.Container>
        ))
      ) : (
        <Flex.Container justifyContent="center">
          <Text.LabelMedium style={{ paddingTop: '3px' }}>
            {translate('USAGES.NO_DATA_BY_FILTER')}
          </Text.LabelMedium>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};

export default QuantityPerItemList;

import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Flex, Input, Text } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';
import { translate } from '@/app/i18n';

interface FileUploadComponentProps {
  onChange: (value: File) => void;
  type?: 'audio' | 'image';
  existingFile?: string;
}

const FileUploadComponent = (props: FileUploadComponentProps) => {
  const { onChange, type, existingFile } = props;
  const [file, setFile] = useState<File>();
  const [allowedExtensions, setAllowedExtensions] = useState<string | undefined>();

  function handleFileUpload(f: File) {
    const extension = f.name.split('.').pop();
    if (allowedExtensions && extension && !allowedExtensions.includes(extension)) {
      toast.error(`Invalid ${type} format.`);
      return;
    }
    setFile(f);
    onChange(f);
  }

  function dropHandler(ev: React.DragEvent<HTMLDivElement>) {
    ev.preventDefault();

    if (ev.dataTransfer && ev.dataTransfer.files[0]) {
      handleFileUpload(ev.dataTransfer.files[0]);
    }
  }

  function dragOverHandler(ev: React.DragEvent<HTMLDivElement>) {
    ev.preventDefault();
  }

  useEffect(() => {
    switch (type) {
      case 'image':
        setAllowedExtensions('.png,.jpg');
        break;
      case 'audio':
        setAllowedExtensions('.mp3,.wav');
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    async function getFile() {
      if (!existingFile) return;
      const response = await fetch(existingFile);
      const data = await response.blob();
      const metadata = {
        type,
      };
      const filename = existingFile.split('/');
      const f = new File([data], filename[filename.length - 1], metadata);
      setFile(f);
    }
    getFile();
  }, []);

  return (
    <div onDrop={dropHandler} onDragOver={dragOverHandler}>
      <Flex.Container
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="2rem"
        style={{
          position: 'relative',
          backgroundColor: Colors.backgroundSidebar,
          padding: '2.5rem',
        }}
      >
        <Text.LabelSmall style={{ color: Colors.grey }}>
          Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
        </Text.LabelSmall>
        <Flex.Container gap="1rem" alignItems="center">
          <Input.File
            id="fileInput"
            onChange={(e) => e.target.files && handleFileUpload(e.target.files[0])}
            hidden
            accept={allowedExtensions}
          />
          <Text.LabelMedium htmlFor="fileInput">
            <Text.SectionSubtitleBold
              style={{
                color: Colors.primary,
                border: `1px solid ${Colors.primary}`,
                padding: '1rem 2rem',
                cursor: 'pointer',
              }}
            >
              {translate('WORKS.UPLOAD')}
            </Text.SectionSubtitleBold>
          </Text.LabelMedium>
          {file === undefined ? (
            <Text.LabelSmall style={{ color: Colors.grey }}>
              {translate('WORKS.DROP_FILE')}
            </Text.LabelSmall>
          ) : (
            <Text.LabelSmall style={{ color: Colors.grey }}>{file.name}</Text.LabelSmall>
          )}
        </Flex.Container>
      </Flex.Container>
    </div>
  );
};

export default FileUploadComponent;

import { Contributor } from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';
import { roles } from '@/app/ui/organisms/RecordingForm/RecordingForm';

interface ArtistsTableComponentPorps {
  artists: Array<Contributor>;
  deleteArtist: (artist: Contributor) => void;
}

const ArtistsTableComponent = ({ artists, deleteArtist }: ArtistsTableComponentPorps) => (
  <Flex.Container className="table" flexDirection="column">
    {artists.length > 0 && (
      <>
        <Flex.Container gap="16px">
          <Flex.Item flex={1} className="th">
            <Text.LabelSmall>{translate('WORKS.OTHER_ARTISTS_NAME')}</Text.LabelSmall>
          </Flex.Item>
          <Flex.Item flex={1} className="th">
            <Text.LabelSmall>{translate('TRACKS.ROLE')}</Text.LabelSmall>
          </Flex.Item>
          <Flex.Item flex={2} />
        </Flex.Container>
        <Flex.Container flexDirection="column" gap="10px">
          {artists.map((artist) => (
            <Flex.Container key={artist.artist.name} className="tr" alignItems="center" gap="16px">
              <Flex.Container className="td" flex={1} alignItems="center">
                {artist.artist.name}
              </Flex.Container>
              <Flex.Container className="td" flex={1} alignItems="center">
                {roles[artist.roleId]}
              </Flex.Container>
              <Flex.Item flex={2} className="td">
                <Button
                  onClick={() => deleteArtist(artist)}
                  style={{ marginLeft: '55px' }}
                  $transparent
                >
                  <Icon.BinIcon width={15} />
                </Button>
              </Flex.Item>
            </Flex.Container>
          ))}
        </Flex.Container>
      </>
    )}
  </Flex.Container>
);

export default ArtistsTableComponent;

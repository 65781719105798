import {
  Cradle,
  Pagination,
  MusicalRelease,
  MusicalReleasesFilters,
  PaginatedEntity,
} from '@solo/app-core';
import { useEffect, useState } from 'react';
import { Flex, Text, MusicalReleasesListComponent } from '@/app/ui';
import { translate } from '@/app/i18n';
import { useCradle } from '@/app/contexts';
import DefaultPagination from '@/app/constants/Pagination';

const WorksPage = () => {
  const { musicalWorksBloc } = useCradle<Cradle>();

  const [musicalReleases, setMusicalReleases] = useState<
    PaginatedEntity<MusicalRelease> | undefined
  >(undefined);
  const [pagination, setPagination] = useState<Pagination>({ limit: 10 });
  const [filters, setFilters] = useState<MusicalReleasesFilters>({});

  const getMusicalReleases = async (p?: Pagination, f?: MusicalReleasesFilters) => {
    const response = await musicalWorksBloc.getMusicalReleases({
      pagination: p,
      filters: f,
    });
    setMusicalReleases(response);
  };

  useEffect(() => {
    getMusicalReleases(pagination, filters);
  }, []);

  const onFilter = (f: MusicalReleasesFilters) => {
    setFilters(f);
    getMusicalReleases(pagination, f);
  };

  const onJumpToPage = async (page: number) => {
    const pag = {
      ...pagination,
      offset: (pagination.limit || DefaultPagination.limit) * (page - 1),
    };
    setPagination(pag);
    getMusicalReleases(pag, filters);
  };

  const onSelectElementsPerPage = async (nElements: number) => {
    const pag = { ...pagination, limit: nElements };
    setPagination(pag);
    getMusicalReleases(pag, filters);
  };

  return (
    <Flex.Container flexDirection="column" gap="3rem" padding="3rem">
      <Flex.Container>
        <Text.Title1>{translate('WORKS_PAGES.MY_RELEASES')}</Text.Title1>
      </Flex.Container>

      <Flex.Container>
        <Flex.Item>
          {musicalReleases && pagination && (
            <MusicalReleasesListComponent
              selectElementsPerPage={onSelectElementsPerPage}
              musicalReleases={musicalReleases}
              filter={onFilter}
              jumpToPage={onJumpToPage}
            />
          )}
        </Flex.Item>
      </Flex.Container>
    </Flex.Container>
  );
};

export default WorksPage;

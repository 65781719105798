import { createRef, useState } from 'react';
import { MusicalRight } from '@solo/app-core';
import { Flex, InputDateWithLabel, InputTextAutocomplete, StatementsList, Text } from '@/app/ui';

import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';

const status = ['Payment pending', 'Paid', 'Error'];

const StatementsPage = () => {
  const fromDateRef = createRef<HTMLInputElement>();
  const toDateRef = createRef<HTMLInputElement>();

  const [statusFilter, setStatusFilter] = useState<string>();
  const [typeFilter, setTypeFilter] = useState<string>();
  const [dateFromFilter, setDateFromFilter] = useState<Date>();
  const [dateToFilter, setDateToFilter] = useState<Date>();

  return (
    <Flex.Container flexDirection="column" gap="3rem" padding="3rem">
      <Flex.Container>
        <Text.PageTitle color={Colors.purple}>{translate('NAVBAR.EARNINGS')} </Text.PageTitle>
        <Text.Title3>&nbsp;/ {translate('NAVBAR.STATEMENTS')}</Text.Title3>
      </Flex.Container>
      <Flex.Container gap="5rem" flexDirection="column">
        <Flex.Container style={{ width: '70%', height: '40px' }} gap="1rem" alignContent="center">
          <Flex.Item flex={3}>
            <InputTextAutocomplete
              placeholder="Status"
              items={[{ value: 'All', label: 'All' }].concat(
                status.map((stat) => ({ value: stat, label: stat })),
              )}
              onChange={(stat) => setStatusFilter(stat)}
            />
          </Flex.Item>
          <Flex.Item flex={3}>
            <InputTextAutocomplete
              placeholder="Type"
              items={[
                { value: 'All', label: 'All' },
                { value: MusicalRight.DISTRIBUTION, label: MusicalRight.DISTRIBUTION },
                { value: MusicalRight.PUBLISHING, label: MusicalRight.PUBLISHING },
              ]}
              onChange={(stat) => setTypeFilter(stat)}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputDateWithLabel ref={fromDateRef} onChange={(date) => setDateFromFilter(date)} />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputDateWithLabel ref={toDateRef} onChange={(date) => setDateToFilter(date)} />
          </Flex.Item>
          <Flex.Item flex={2} />
        </Flex.Container>
        <StatementsList
          statusFilter={statusFilter}
          typeFilter={typeFilter}
          dateFromFilter={dateFromFilter}
          dateToFilter={dateToFilter}
        />
      </Flex.Container>
    </Flex.Container>
  );
};

export default StatementsPage;

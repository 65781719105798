import { Cradle } from '@solo/app-core';
import { useNavigate } from 'react-router-dom';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Button, Flex, RouterLink } from '@/app/ui/atoms';
import { Dropdown, Icon } from '@/app/ui/molecules';
import { useCradle } from '@/app/contexts';

const UserDropDown = () => {
  const { authBloc } = useCradle<Cradle>();
  const navigate = useNavigate();

  function handleLogout() {
    authBloc.logout();
    navigate('/auth/login');
  }
  return (
    <Dropdown.Container
      style={{ position: 'absolute', right: 0, marginRight: '4rem', marginTop: '2.7rem' }}
    >
      <Dropdown.Button>
        <Icon.User width={16} />
      </Dropdown.Button>
      <Dropdown.Content>
        <Flex.Container flexDirection="column">
          <RouterLink
            to="/profile"
            $backgroundColorHover={Colors.purple}
            $transparent
            $size="md"
            $full
            style={{ justifyContent: 'flex-start', color: Colors.white }}
          >
            {translate('PAGE_TITLE.PROFILE')}
          </RouterLink>
          <Button
            $backgroundColorHover={Colors.purple}
            $transparent
            $size="md"
            $full
            style={{ justifyContent: 'space-between', color: Colors.white }}
            onClick={() => {
              handleLogout();
            }}
          >
            {translate('ACCOUNT.LOG_OUT')} <Icon.LogOut width={21} color={Colors.white} />
          </Button>
        </Flex.Container>
      </Dropdown.Content>
    </Dropdown.Container>
  );
};

export default UserDropDown;

import { useEffect, useState } from 'react';
import { Cradle, DigitalPlatformId, MusicalRight } from '@solo/app-core';
import { Flex } from '@/app/ui/atoms';
import { useCradle } from '@/app/contexts';
import { BarData } from '@/app/ui/organisms/CustomBarChart/CustomBarChart';
import { useBlocState } from '@/app/hooks';
import { monthNumberToName } from '@/app/utils/MonthNumberToName';
import { ButtonsNavBar, InputTextAutocomplete } from '@/app/ui/molecules';
import CustomLineChart, { LineChartData } from '@/app/ui/organisms/CustomLineChart/CustomLineChart';
import { translate } from '@/app/i18n';
import { stringToInt } from '@/app/utils/StringToInt';

type RevenueByMonth = {
  [key: string]: number;
};

const year: RevenueByMonth = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sept: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0,
};

export type EarningData = {
  [key: string]: RevenueByMonth;
};

const RevenueMonthly = () => {
  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const [earningsData, setEarningsData] = useState<LineChartData>({});

  const [currentGroupFilter, setCurrentGroupFilter] = useState<DigitalPlatformId | undefined>();
  const [activeTab, setActiveTab] = useState<string>();
  const [earningType, setEarningType] = useState<string | undefined>();

  const { consumptionReportsBloc, authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const digitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  useEffect(() => {
    async function getTopSongsData() {
      if (authState.kind !== 'AuthenticatedAuthState') return;
      const earningsFiltered = await consumptionReportsBloc.getEarnings({
        digitalPlatformId: currentGroupFilter,
        rightType: earningType ? (earningType as MusicalRight) : undefined,
        userId: authState.user.id,
      });

      const earnings: EarningData = {};
      earningsFiltered.forEach((earning) => {
        const month = monthNumberToName(earning.date.getMonth());
        if (earnings[earning.date.getFullYear()] === undefined) {
          const newYear = { ...year };
          newYear[month] = earning.amount;
          earnings[earning.date.getFullYear().toString()] = newYear;
        }
        earnings[earning.date.getFullYear().toString()][month] += earning.amount;
      });

      const earningsBarData: LineChartData = {};
      Object.keys(earnings).forEach((y: string) => {
        const playsYear: BarData[] = [];
        Object.keys(earnings[y]).forEach((month: string) => {
          playsYear.push({
            x: month,
            y: Number.isNaN(earnings[y][month]) ? 0 : earnings[y][month],
          });
        });
        earningsBarData[y] = playsYear;
      });
      setEarningsData(earningsBarData);
    }
    getTopSongsData();
  }, [currentGroupFilter, activeTab]);

  function changeTab(tab: string) {
    switch (tab) {
      case translate('EARNINGS.ALL'):
        setEarningType(undefined);
        break;
      case translate('DIGITAL_DISTRIBUTION.DIGITAL_DISTRIBUTION'):
        setEarningType(MusicalRight.DISTRIBUTION);
        break;
      default:
        setEarningType(MusicalRight.PUBLISHING);
        break;
    }
    setActiveTab(tab);
  }

  return (
    <Flex.Container flexDirection="column" gap="2.5rem">
      <ButtonsNavBar
        defaultTab={translate('EARNINGS.ALL')}
        tabs={[
          translate('EARNINGS.ALL'),
          translate('DIGITAL_DISTRIBUTION.DIGITAL_DISTRIBUTION'),
          translate('EARNINGS.PUBLISHING'),
        ]}
        onSelectTab={(tab) => changeTab(tab)}
      />
      <Flex.Container style={{ width: '20%' }} gap="1rem">
        <InputTextAutocomplete
          placeholder="Platform..."
          items={[{ value: 'All', label: 'All' }].concat(
            digitalPlatforms.map((p) => ({ value: p.id.toString(), label: p.name })),
          )}
          onChange={(groupFilter) =>
            groupFilter === 'All'
              ? setCurrentGroupFilter(undefined)
              : setCurrentGroupFilter(stringToInt(groupFilter))
          }
        />
      </Flex.Container>
      <CustomLineChart yaxis="Revenue" data={earningsData} />
    </Flex.Container>
  );
};

export default RevenueMonthly;

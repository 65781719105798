import { MusicalRelease, MusicalReleasesFilters, PaginatedEntity } from '@solo/app-core';
import styled from 'styled-components';
import { useState } from 'react';
import {
  Flex,
  Text,
  Section,
  Input,
  Img,
  RouterLink,
  ElementsPerPageSelectorClick,
  StatusBullet,
  BulletStatus,
} from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import loginImg from '@/app/assets/images/login-img.png';
import WorksFilterComponent from '@/app/ui/organisms/Works/WorksListComponent/WorksFilterComponent/WorksFilterComponent';
import Pagination from '@/app/ui/organisms/Pagination/Pagination';
import { convertArrayToString } from '@/app/utils/ArrayToString';

interface MusicalReleaseListComponentProps {
  musicalReleases: PaginatedEntity<MusicalRelease>;
  className?: string;
  filter: (filters: MusicalReleasesFilters) => void;
  jumpToPage: (page: number) => void;
  selectElementsPerPage: (nElements: number) => void;
}
const releases = [10, 20, 30, 40, 50];

const statuses: BulletStatus[] = [
  BulletStatus.Rejected,
  BulletStatus.Pending,
  BulletStatus.Distributed,
];

const getRandomStatus = () => {
  const randomIndex = Math.floor(Math.random() * statuses.length);
  return statuses[randomIndex];
};

const MusicalReleasesListComponent = ({
  musicalReleases,
  className,
  filter,
  jumpToPage,
  selectElementsPerPage,
}: MusicalReleaseListComponentProps) => {
  const [selectedMusicalReleasesIds, setSelectedMusicalReleasesIds] = useState<number[]>([]);

  function handleCheckboxClick(musicalReleaseId?: number) {
    let currentSelectedMusicalReleasesIds = [...selectedMusicalReleasesIds];
    if (musicalReleaseId === undefined) {
      if (currentSelectedMusicalReleasesIds.length < musicalReleases.data.length) {
        currentSelectedMusicalReleasesIds = musicalReleases.data.map((mr) => mr.releaseId);
      } else {
        currentSelectedMusicalReleasesIds = [];
      }
    } else if (currentSelectedMusicalReleasesIds.includes(musicalReleaseId)) {
      const elementIndex = currentSelectedMusicalReleasesIds.indexOf(musicalReleaseId);
      currentSelectedMusicalReleasesIds.splice(elementIndex, 1);
    } else {
      currentSelectedMusicalReleasesIds.push(musicalReleaseId);
    }
    setSelectedMusicalReleasesIds(currentSelectedMusicalReleasesIds);
  }

  return (
    <>
      <WorksFilterComponent filter={filter} />
      <ElementsPerPageSelectorClick
        position="right"
        totalElements={musicalReleases.pagination.totalElements}
        options={releases}
        defaultElementsPerPage={releases[0]}
        onChange={(p) => selectElementsPerPage(p)}
      />
      <Section className={className}>
        <Flex.Container flex={1} className="table" flexDirection="column">
          <Flex.Container flex={1} className="thead">
            <Flex.Container flex={3} className="th ml-2" alignItems="center">
              <Input.Checkbox
                style={{ marginRight: 15 }}
                onChange={() => handleCheckboxClick()}
                checked={selectedMusicalReleasesIds.length === musicalReleases.data.length}
              />
              <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
                {translate('WORKS.TITLE')}
              </Text.Paragraph>
            </Flex.Container>
            <Flex.Item flex={3} className="th">
              {translate('WORKS.ARTIST')}
            </Flex.Item>
            {/* <Flex.Item flex={2} className="th">
              {translate('WORKS.TYPE')}
            </Flex.Item> */}
            {/* <Flex.Item flex={2} className="th">
            {translate('WORKS.STATUS')}
          </Flex.Item> */}
            <Flex.Item flex={2} className="th">
              {translate('WORKS.RELEASE_STATUS')}
            </Flex.Item>
          </Flex.Container>
          <Flex.Container className="tbody" flexDirection="column">
            {musicalReleases.data.map((musicalRelease) => {
              const randomStatus = getRandomStatus();
              return (
                <Flex.Container key={musicalRelease.releaseId} className="tr" alignItems="center">
                  <Flex.Container className="td ml-2" flex={3} alignItems="center">
                    <Input.Checkbox
                      style={{ marginRight: 15 }}
                      onChange={() => handleCheckboxClick(musicalRelease.releaseId)}
                      checked={selectedMusicalReleasesIds.includes(musicalRelease.releaseId)}
                    />
                    <Img
                      src={musicalRelease.resourceId || loginImg}
                      height={40}
                      width={40}
                      style={{ marginRight: 15 }}
                    />
                    <RouterLink $transparent $variant="primary" to={`${musicalRelease.releaseId}`}>
                      <Text.Paragraph fontWeight={600} color={Colors.primary}>
                        {musicalRelease.name}
                      </Text.Paragraph>
                    </RouterLink>
                  </Flex.Container>
                  <Flex.Item flex={3} className="td">
                    {convertArrayToString(
                      [musicalRelease.artistName].concat(
                        musicalRelease.contributors?.map((artist) => artist.artist.name),
                      ) || [musicalRelease.artistName],
                    )}
                  </Flex.Item>
                  <Flex.Container
                    flex={2}
                    flexDirection="row"
                    alignItems="center"
                    className="td capitalize"
                  >
                    <StatusBullet status={randomStatus} />
                    {randomStatus}
                  </Flex.Container>
                </Flex.Container>
              );
            })}
          </Flex.Container>
        </Flex.Container>
      </Section>
      <Pagination
        totalElements={musicalReleases.pagination.totalElements}
        pagination={musicalReleases.pagination}
        jumpToPage={jumpToPage}
        maxDisplayedPages={8}
      />
    </>
  );
};

// export default WorksListComponent;
export default styled(MusicalReleasesListComponent)`
  .box {
    padding: 2px 0px;
    width: fit-content;
    color: ${Colors.white};
    width: 80px;
    text-align: center;
  }
  .td.capitalize {
    text-transform: capitalize;
  }
`;

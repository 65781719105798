import { forwardRef, useEffect, useState } from 'react';
import Select from 'react-select/dist/declarations/src/Select';
import ReactSelect, { SingleValue } from 'react-select';
import { Flex, Text } from '@/app/ui/atoms';
import { Icon } from '@/app/ui/molecules';
import Colors from '@/app/styles/Colors';

export type Item = {
  value: string;
  label: string;
};

export type ItemGroup = {
  items: Item[];
};

interface InputTextAutocompleteProps {
  disabled?: boolean;
  label?: string;
  placeholder?: string;
  tooltip?: boolean;
  items: Item[];
  isSearchable?: boolean;
  defaultOption?: Item;
  mandatoryError?: boolean;
  labelColor?: string;
  onChange?: (type: string) => void;
}

const InputTextAutocomplete = forwardRef<Select<Item>, InputTextAutocompleteProps>(
  (props: InputTextAutocompleteProps, ref) => {
    const {
      disabled,
      label,
      placeholder,
      tooltip,
      items,
      isSearchable = true,
      defaultOption,
      mandatoryError,
      labelColor,
      onChange,
    } = props;

    const [currentValue, setCurrentValue] = useState<Item | undefined>(defaultOption);

    const handleOnChange = (option: SingleValue<Item>) => {
      if (!onChange || !(onChange instanceof Function) || option === null) return;
      onChange(option?.value);
      setCurrentValue(option);
    };

    useEffect(() => {
      if (currentValue) return;

      setCurrentValue(defaultOption);
    }, [defaultOption]);

    return (
      <Flex.Container flexDirection="column" style={{ width: '100%', position: 'relative' }}>
        <Flex.Container gap="0.5rem" alignItems="center">
          <Text.LabelSmall
            htmlFor="title_input"
            style={{ color: mandatoryError ? Colors.red : labelColor, marginBottom: '2px' }}
          >
            {label}
          </Text.LabelSmall>
          {tooltip && <Icon.InfoIcon />}
        </Flex.Container>
        <ReactSelect
          isDisabled={disabled}
          isSearchable={isSearchable}
          styles={{
            control: (baseStyles) => ({
              ...baseStyles,
              borderRadius: 0,
              border: mandatoryError ? `1px solid ${Colors.red}` : '',
              backgroundColor: disabled ? Colors.borderInput : Colors.white,
            }),
            option: (baseStyles) => ({
              ...baseStyles,
              color: Colors.black,
            }),
          }}
          onChange={(item) => handleOnChange(item)}
          ref={ref}
          value={currentValue !== undefined ? currentValue : null}
          options={items}
          placeholder={placeholder}
          maxMenuHeight={160}
        />
      </Flex.Container>
    );
  },
);

export default InputTextAutocomplete;

import { Cradle, MusicalRight, TotalInsights } from '@solo/app-core';
import { useEffect, useState } from 'react';
import { Flex, PlaysBySongAndPlatform, RouterLink, Text, Top3EarningPlatforms } from '@/app/ui';
import { translate } from '@/app/i18n';
import MySongsInsights from '@/app/ui/organisms/Dashboard/MySongsInsights';
import { useCradle } from '@/app/contexts';
import Colors from '@/app/styles/Colors';
import { useBlocState } from '@/app/hooks';

const DashboardPage = () => {
  const { musicalWorksBloc, consumptionReportsBloc, authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const [insightData, setInsightData] = useState<TotalInsights | undefined>();

  useEffect(() => {
    async function getInsightsData() {
      if (authState.kind !== 'AuthenticatedAuthState') return;
      const musicalReleases = await musicalWorksBloc.getMusicalReleases({
        filters: { userId: authState.user.id },
      });

      // let totalSingles = 0;
      // let totalEps = 0;
      // let totalAlbums = 0;

      // musicalReleases.data.forEach((mr) => {
      //   switch (mr.type) {
      //     case MusicalReleaseType.SINGLE:
      //       totalSingles += 1;
      //       break;
      //     case MusicalReleaseType.EP:
      //       totalEps += 1;
      //       break;
      //     case MusicalReleaseType.ALBUM:
      //       totalAlbums += 1;
      //       break;
      //     default:
      //       break;
      //   }
      // });

      let distributionRevenue = 0;
      let publishingRevenue = 0;

      const totalEarnings = await consumptionReportsBloc.getEarnings({
        userId: authState.user.id,
      });

      totalEarnings.forEach((earning) => {
        switch (earning.rightType) {
          case MusicalRight.DISTRIBUTION:
            distributionRevenue += earning.amount;
            break;
          case MusicalRight.PUBLISHING:
            publishingRevenue += earning.amount;
            break;
          default:
            break;
        }
      });

      setInsightData({
        totalReleases: musicalReleases.data.length,
        totalNetRevenue: distributionRevenue + distributionRevenue,
        distributionRevenue,
        publishingRevenue,
      });
    }
    getInsightsData();
  }, []);

  return (
    <Flex.Container flexDirection="column" padding="3rem">
      <Flex.Container flexDirection="column" gap="3rem">
        <Flex.Container>
          <RouterLink $transparent to="/dashboard">
            <Text.Title2 style={{ marginRight: 5 }}>{translate('NAVBAR.DASHBOARD')}</Text.Title2>
          </RouterLink>
        </Flex.Container>
        <Flex.Container gap="3rem">
          <Flex.Container flexDirection="column" flex={1} gap="4rem">
            <Flex.Container style={{ width: '100%' }}>
              <Flex.Container flexDirection="column" gap="3rem">
                <Text.SubtitleBold>{translate('DASHBOARD.MY_RELEASES_INSIGHT')}</Text.SubtitleBold>
                <MySongsInsights insightData={insightData} />
              </Flex.Container>
              <Flex.Container flexDirection="column" gap="1rem" />
            </Flex.Container>
            <Flex.Container style={{ width: '100%' }}>
              <Flex.Container flexDirection="column" gap="1rem" flex={1}>
                <Text.SubtitleBold>{translate('USAGES.TOP_SONGS_BY_PLATFORM')}</Text.SubtitleBold>
                <PlaysBySongAndPlatform />
              </Flex.Container>
            </Flex.Container>
          </Flex.Container>
          <Flex.Container flexDirection="column" flex={1} gap="4rem" style={{ width: '100%' }}>
            <Flex.Container style={{ width: '100%' }}>
              <Flex.Container flexDirection="column" gap="3rem" flex={1}>
                <Text.SubtitleBold>{translate('BUTTON.ADD_RELEASE')}</Text.SubtitleBold>
                <Text.LabelMedium>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Text.LabelMedium>
                <Flex.Container gap="2rem">
                  <RouterLink to="/works/add-musical-release" $size="md" $full>
                    <Text.SectionSubtitleBold style={{ color: Colors.white }}>
                      {translate('BUTTON.NEW_RELEASE')}
                    </Text.SectionSubtitleBold>
                  </RouterLink>
                  {/* <RouterLink to="/works/add-song/ep" $size="md" $full>
                    <Text.SectionSubtitleBold style={{ color: Colors.white }}>
                      {translate('BUTTON.NEW_EP')}
                    </Text.SectionSubtitleBold>
                  </RouterLink>
                  <RouterLink to="/works/add-song/album" $size="md" $full>
                    <Text.SectionSubtitleBold style={{ color: Colors.white }}>
                      {translate('BUTTON.NEW_ALBUM')}
                    </Text.SectionSubtitleBold>
                  </RouterLink> */}
                </Flex.Container>
              </Flex.Container>
            </Flex.Container>
            <Flex.Container style={{ width: '100%' }}>
              <Flex.Container flexDirection="column" gap="1rem" flex={1}>
                <Text.SubtitleBold>
                  {translate('DASHBOARD.TOP_3_EARNING_PLATFORMS')}
                </Text.SubtitleBold>
                <Top3EarningPlatforms />
              </Flex.Container>
            </Flex.Container>
            {/* <Flex.Container style={{ width: '100%' }}>
              <Flex.Container flexDirection="column" gap="1rem" flex={1}>
                <Text.SubtitleBold>{translate('DASHBOARD.PLAYS_COUNTRY')}</Text.SubtitleBold>
                <PlaysByCountry />
              </Flex.Container>
            </Flex.Container> */}
          </Flex.Container>
        </Flex.Container>
      </Flex.Container>
      {/* <Flex.Container style={{ marginTop: '-10px' }}>
        <DashboardHelp />
      </Flex.Container> */}
    </Flex.Container>
  );
};

export default DashboardPage;

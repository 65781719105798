import { useEffect, useState } from 'react';
import {
  Cradle,
  FinancialData,
  FinancialUserType,
  TerritoryType,
  UpdateFinancialDataProps,
} from '@solo/app-core';
import { toast } from 'react-toastify';
import {
  Flex,
  Text,
  // Container,
  Section,
  Button,
  InputTextAutocomplete,
  Item,
  InputWithLabel,
  //  RouterLinkArrow
} from '@/app/ui';

import { translate } from '@/app/i18n';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

//   bankAccount?: string;
//   swift?: string;
//   country?: TerritoryInterface;
//   currency?: CurrencyInterface;
//   countryId?: number;
//   currencyId?: string;
// }

// interface FinancialDataInterface {
//   financialUserType?: FinancialUserType;
//   bankInfo?: BankInfoInterface;
// }

// enum FinancialUserType {
//   COMPANY = 'company',
//   INDIVIDUAL = 'individual',
//   SELF_EMPLOYED = 'self_employed',
// }

// const FINANCIAL_USER_TYPE = Object.values(FinancialUserType);

// type FinancialUserTypeType = typeof FINANCIAL_USER_TYPE[number];

const FinancialDataComponent = () => {
  const [financialData, setFinancialData] = useState<Partial<FinancialData>>({});
  const [countryOptions, setCountryOptions] = useState<Item[] | undefined>(undefined);
  const [currencyOptions, setCurrencyOptions] = useState<Item[] | undefined>(undefined);
  const [userType, setUserType] = useState<FinancialUserType | undefined>();

  const { authBloc, territoryBloc, currencyBloc, userBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  const userTypeOptions: Array<Item> = Object.entries(FinancialUserType).map((uType) => ({
    label: translate(`FINANCIAL_DATA_INFO.${uType[0]}`),
    value: translate(`FINANCIAL_DATA_INFO.${uType[1]}`),
  }));

  function validateFinancialForm(): UpdateFinancialDataProps | undefined {
    if (
      userType !== undefined &&
      financialData.country !== undefined &&
      financialData.currency !== undefined &&
      financialData.iban !== undefined &&
      financialData.swift !== undefined &&
      financialData.postalCode !== undefined &&
      financialData.name !== undefined &&
      financialData.address !== undefined &&
      financialData.cif_nif !== undefined &&
      financialData.city !== undefined
    ) {
      return financialData;
    }

    toast.error(translate('TOAST.MANDATORY_ERROR'));
    return undefined;
  }

  const userId = authState.kind === 'AuthenticatedAuthState' ? authState.user.id : '';

  const saveBankInformation = async () => {
    try {
      const updateFinancialData = validateFinancialForm();
      if (!updateFinancialData) return;
      await userBloc.saveFinancialData({ ...updateFinancialData, type: userType }, userId);
      toast.success(translate('TOAST.CHANGES_SAVED'));
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }
  };

  const getFinancialData = async () => {
    try {
      const financialDataFetched = await userBloc.getFinancialData(userId);

      if (financialDataFetched !== undefined) {
        setFinancialData(financialDataFetched);
        setUserType(financialDataFetched.type);
      }
    } catch (error) {
      // TODO handle errors
    }
  };

  const getCountries = async () => {
    try {
      const countriesFetched = await territoryBloc.getTerritories(TerritoryType.COUNTRY);

      if (countriesFetched === undefined) return;

      const countryOpt: Array<Item> = countriesFetched.map((country) => ({
        label: country.name,
        value: country.tis.toString() ? country.tis.toString() : '0',
      }));
      setCountryOptions(countryOpt);
    } catch (error) {
      // TODO handle errors
    }
  };

  const getCurrencies = async () => {
    try {
      const currenciesFetched = await currencyBloc.getCurrencies();

      if (currenciesFetched === undefined) return;

      const currencyOpt: Array<Item> = currenciesFetched.map((currency) => ({
        label: currency.name,
        value: currency.id,
      }));

      setCurrencyOptions(currencyOpt);
    } catch (error) {
      // TODO handle errors
    }
  };

  useEffect(() => {
    getCountries();
    getCurrencies();
    getFinancialData();
  }, []);

  return (
    <>
      <Section py={15} style={{ flexDirection: 'column', paddingTop: 0 }}>
        <Text.SectionTitle style={{ marginBottom: 32 }}>
          {translate('PROFILE_PAGES.FINANCIAL_DATA')}
        </Text.SectionTitle>
        <Flex.Container gap="18px" flexDirection="column">
          <Flex.Container gap="18px">
            <Flex.Item flex={1}>
              <InputTextAutocomplete
                label={translate('FINANCIAL_DATA_INFO.USER_TYPE')}
                placeholder={translate('FINANCIAL_DATA_INFO.SELECT')}
                items={userTypeOptions}
                defaultOption={
                  financialData.type
                    ? {
                        value: financialData.type,
                        label: financialData.type,
                      }
                    : undefined
                }
                onChange={(type) => setUserType(type as FinancialUserType)}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <InputWithLabel
                disabled={userType === undefined}
                label={translate('PROFILE_INFO.NAME')}
                placeholder={translate('PROFILE_INFO.NAME')}
                value={financialData?.name}
                onChange={(name) => setFinancialData({ ...financialData, name })}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <InputWithLabel
                disabled={userType === undefined}
                label={translate('FORMS.SURNAME')}
                placeholder={translate('FORMS.SURNAME')}
                value={financialData?.surname}
                onChange={(surname) => setFinancialData({ ...financialData, surname })}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <InputWithLabel
                disabled={userType === undefined}
                label={translate('FINANCIAL_DATA.CIF_NIF')}
                placeholder={translate('FINANCIAL_DATA.CIF_NIF')}
                value={financialData?.cif_nif}
                onChange={(cif_nif) => setFinancialData({ ...financialData, cif_nif })}
              />
            </Flex.Item>
            <Flex.Item flex={3} />
          </Flex.Container>
          <Flex.Container gap="18px">
            <Flex.Item flex={1}>
              <InputWithLabel
                disabled={userType === undefined}
                label={translate('FORMS.CITY')}
                placeholder={translate('FORMS.CITY')}
                value={financialData.city}
                onChange={(city) => setFinancialData({ ...financialData, city })}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <InputWithLabel
                disabled={userType === undefined}
                label={translate('FORMS.POSTAL_CODE')}
                placeholder={translate('FORMS.POSTAL_CODE')}
                value={financialData.postalCode}
                onChange={(postalCode) => setFinancialData({ ...financialData, postalCode })}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <InputWithLabel
                disabled={userType === undefined}
                label={translate('FORMS.ADDRESS')}
                placeholder={translate('FORMS.ADDRESS')}
                value={financialData.address}
                onChange={(address) => setFinancialData({ ...financialData, address })}
              />
            </Flex.Item>

            <Flex.Item flex={3} />
          </Flex.Container>
        </Flex.Container>
      </Section>
      <Section py={5} style={{ flexDirection: 'column' }}>
        <Text.SectionTitle style={{ marginBottom: '32px' }}>
          {translate('FINANCIAL_DATA.BANK_INFO')}
        </Text.SectionTitle>
        <Flex.Container>
          <Flex.Item flex={1}>
            <Text.Paragraph style={{ marginBottom: '32px' }}>
              {translate('FINANCIAL_DATA.BANK_ACCOUNT_TEXT')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item flex={1} />
        </Flex.Container>
        <Flex.Container>
          <Flex.Container gap="3rem" flex={1} flexDirection="column">
            <Flex.Container gap="18px">
              <Flex.Item flex={1}>
                <InputTextAutocomplete
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.COUNTRY')}
                  placeholder={translate('FINANCIAL_DATA_INFO.COUNTRY')}
                  defaultOption={
                    financialData?.country
                      ? {
                          value:
                            countryOptions?.find(
                              (c) => c.value === financialData.country?.toString(),
                            )?.value || '',
                          label:
                            countryOptions?.find(
                              (c) => c.value === financialData.country?.toString(),
                            )?.label || '',
                        }
                      : undefined
                  }
                  items={countryOptions || []}
                  onChange={(country) =>
                    setFinancialData({ ...financialData, country: parseInt(country, 10) })
                  }
                />
              </Flex.Item>
              <Flex.Item flex={1}>
                <InputTextAutocomplete
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.CURRENCY')}
                  placeholder={translate('FINANCIAL_DATA_INFO.CURRENCY')}
                  defaultOption={
                    financialData?.currency
                      ? {
                          value: financialData.currency,
                          label: financialData.currency,
                        }
                      : undefined
                  }
                  items={currencyOptions || []}
                  onChange={(currency) => setFinancialData({ ...financialData, currency })}
                />
              </Flex.Item>
            </Flex.Container>
            <Flex.Container gap="18px">
              <Flex.Item flex={1}>
                <InputWithLabel
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.BANK_ACCOUNT')}
                  placeholder={translate('FINANCIAL_DATA_INFO.BANK_ACCOUNT')}
                  value={financialData.iban}
                  onChange={(iban) => setFinancialData({ ...financialData, iban })}
                />
              </Flex.Item>
              <Flex.Item flex={1}>
                <InputWithLabel
                  disabled={userType === undefined}
                  label={translate('FINANCIAL_DATA_INFO.SWIFT')}
                  placeholder={translate('FINANCIAL_DATA_INFO.SWIFT')}
                  value={financialData.swift}
                  onChange={(swift) => setFinancialData({ ...financialData, swift })}
                />
              </Flex.Item>
            </Flex.Container>
            {/* <p className={`${error ? 'error' : 'success'}`}>{helperText}</p> */}
            <Flex.Container gap="18px">
              <Flex.Item flex={1} />
              <Flex.Item flex={1}>
                <Button
                  $size="md"
                  $full
                  $variant="primary"
                  onClick={() => {
                    saveBankInformation();
                  }}
                >
                  {translate('BUTTON.SAVE_CHANGES')}
                </Button>
              </Flex.Item>
            </Flex.Container>
          </Flex.Container>
          <Flex.Item flex={1} />
        </Flex.Container>
      </Section>
    </>
  );
};

export default FinancialDataComponent;

import { useEffect, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { User, Cradle, TerritoryType, NewPasswordI, UpdateUserProps } from '@solo/app-core';
import { toast, ToastContainer } from 'react-toastify';
import {
  Button,
  Flex,
  Section,
  Text,
  FormGroup,
  InputDateWithLabel,
  InputWithLabel,
  InputTextAutocomplete,
  Item,
} from '@/app/ui';
import { translate } from '@/app/i18n';
import { useBlocState } from '@/app/hooks';
import { useCradle } from '@/app/contexts';
import ChangePasswordModal from './ChangePasswordModal';

const ProfileInfoComponent = () => {
  const { userBloc, territoryBloc } = useCradle<Cradle>();
  const userState = useBlocState(userBloc);

  const [user, setUser] = useState<User | undefined>(undefined);
  const [updatedUserInfo, setUpdatedUserInfo] = useState<UpdateUserProps>({});
  const [newPassword, setNewPassword] = useState<NewPasswordI | undefined>(undefined);
  const [isOpenPasswordModal, setIsOpenPasswordModal] = useState<boolean>(false);
  const [countryOptions, setCountryOptions] = useState<Item[] | undefined>(undefined);
  const [currentCountryOption, setCurrentCountryOpton] = useState<Item | undefined>(undefined);

  // const birthdayFormat = () => dayjs(user?.birthday).format('YYYY-DD-MM');

  const getCountries = async () => {
    try {
      const countriesFetched = await territoryBloc.getTerritories(TerritoryType.COUNTRY);

      if (countriesFetched === undefined) return;

      const countryOpt: Item[] = countriesFetched.map((c) => ({
        label: c.name,
        value: c.tis ? c.tis.toString() : '0',
      }));
      setCountryOptions(countryOpt);
    } catch (error) {
      // TODO handle errors
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    async function getMeUser() {
      let meUser: User | undefined;

      if (userState.kind === 'LoadedUserState') {
        meUser = userState.user;
      } else {
        meUser = await userBloc.getUser();
      }

      setUser(meUser);
      if (!meUser?.country || !countryOptions) return;
      const userCountry = countryOptions.find((c) => c.value === meUser?.country);
      setCurrentCountryOpton(userCountry);
    }
    getMeUser();
  }, [userState, countryOptions]);

  function validateUpdateUserForm(): UpdateUserProps {
    if (updatedUserInfo.firstName === '') throw new Error('Name invalid!');

    return updatedUserInfo;
  }

  function saveProfileChanges() {
    async function saveUser() {
      if (!user) return;
      try {
        const u = validateUpdateUserForm();
        await userBloc.updateUser(u);
        toast.success(translate('TOAST.CHANGES_SAVED'));
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
    }
    saveUser();
  }

  function changePassword() {
    if (!user || !newPassword || newPassword.password !== newPassword.repeatPassword) return;
    userBloc.changePassword(user.id, newPassword.password);
    setIsOpenPasswordModal(false);
  }

  if (user === undefined) return null;
  return (
    <Flex.Container flexDirection="column" style={{ width: '550px', paddingBottom: '20px' }}>
      <ToastContainer />
      <Flex.Container flexDirection="column" gap="2rem">
        <Text.SectionTitle>{translate('PROFILE_PAGES.PROFILE_INFO')}</Text.SectionTitle>
        <Flex.Container gap="10px">
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.NAME')}
              placeholder={translate('FORMS.NAME')}
              value={user.name}
              onChange={(firstName) => setUpdatedUserInfo({ ...updatedUserInfo, firstName })}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.SURNAME')}
              placeholder={translate('FORMS.SURNAME')}
              value={user.surname}
              onChange={(lastName) => setUpdatedUserInfo({ ...updatedUserInfo, lastName })}
            />
          </Flex.Item>
        </Flex.Container>
        <Flex.Container gap="10px">
          <Flex.Item flex={1}>
            <InputDateWithLabel
              label={translate('FORMS.BIRTHDAY')}
              defaultValue={user.birthday ? user.birthday : undefined}
              onChange={(birthday) => setUpdatedUserInfo({ ...updatedUserInfo, birthday })}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.ID_NUMBER')}
              placeholder={translate('FORMS.ID_NUMBER')}
              value={user.idNumber}
              onChange={(idNumber) => setUpdatedUserInfo({ ...updatedUserInfo, idNumber })}
            />
          </Flex.Item>
        </Flex.Container>
        <Flex.Container gap="10px">
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.EMAIL')}
              placeholder={translate('FORMS.EMAIL')}
              value={user.email}
              disabled
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.PHONE_NUMBER')}
              placeholder={translate('FORMS.PHONE_NUMBER')}
              value={user.phone}
              onChange={(phone) => setUpdatedUserInfo({ ...updatedUserInfo, phone })}
            />
          </Flex.Item>
        </Flex.Container>
        <Flex.Container gap="10px">
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.ADDRESS')}
              placeholder={translate('FORMS.ADDRESS')}
              value={user.address}
              onChange={(address) => setUpdatedUserInfo({ ...updatedUserInfo, address })}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.CITY')}
              value={user.city}
              placeholder={translate('FORMS.CITY')}
              onChange={(city) => setUpdatedUserInfo({ ...updatedUserInfo, city })}
            />
          </Flex.Item>
        </Flex.Container>
        <Flex.Container gap="10px">
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.POSTAL_CODE')}
              placeholder={translate('FORMS.POSTAL_CODE')}
              value={user.postalCode}
              onChange={(postalCode) => setUpdatedUserInfo({ ...updatedUserInfo, postalCode })}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <InputWithLabel
              label={translate('FORMS.PROVINCE_STATE')}
              value={user.province}
              placeholder={translate('FORMS.PROVINCE_STATE')}
              onChange={(province) => setUpdatedUserInfo({ ...updatedUserInfo, province })}
            />
          </Flex.Item>
        </Flex.Container>
        <Flex.Container gap="10px">
          <Flex.Item flex={1}>
            <InputTextAutocomplete
              label={translate('FORMS.COUNTRY')}
              items={countryOptions || []}
              defaultOption={currentCountryOption}
              onChange={(country) => setUpdatedUserInfo({ ...updatedUserInfo, country })}
            />
          </Flex.Item>
          <Flex.Item flex={1} />
        </Flex.Container>
      </Flex.Container>
      <Section style={{ flexDirection: 'column' }}>
        <Text.SectionTitle style={{ marginBottom: '20px', marginTop: '30px' }}>
          {translate('PROFILE_INFO.PASSWORD')}
        </Text.SectionTitle>
        <Flex.Container alignItems="center">
          <Flex.Item flex={1}>
            <FormGroup
              className="mb-5"
              type="password"
              id="password"
              path="password"
              name="password"
              label={translate('FORMS.PASSWORD')}
              placeholder={translate('FORMS.PASSWORD')}
              value={newPassword?.password || 'password'}
              readOnly
            />
          </Flex.Item>
          <Flex.Item flex={1} className="pl-3">
            <Button $variant="primary" $transparent onClick={() => setIsOpenPasswordModal(true)}>
              <Text.Span fontWeight={700}>{translate('PROFILE_INFO.CHANGE_PASSWORD')}</Text.Span>
            </Button>
          </Flex.Item>
        </Flex.Container>
        <Flex.Container justifyContent="flex-end" className="mt-3">
          <Button
            $size="md"
            $variant="primary"
            style={{ width: '50%' }}
            onClick={() => saveProfileChanges()}
          >
            <Text.Span>{translate('PROFILE_INFO.SAVE_CHANGES')}</Text.Span>
          </Button>
        </Flex.Container>
      </Section>

      <ChangePasswordModal
        isOpen={isOpenPasswordModal}
        onChangePassword={(passwordForm) => {
          setNewPassword(passwordForm);
        }}
        cancel={() => setIsOpenPasswordModal(false)}
        save={() => changePassword()}
      />
    </Flex.Container>
  );
};

export default ProfileInfoComponent;

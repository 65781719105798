import { createRef, useEffect, useState } from 'react';
import { Cradle, PaginatedEntity, Pagination, Recording, TotalEarnings } from '@solo/app-core';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { useCradle } from '@/app/contexts';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import {
  ElementsPerPageSelectorClick,
  InputSearch,
  Pagination as PaginationComponent,
} from '@/app/ui';
import EarningWorksList from '@/app/ui/organisms/Royalties/EarningWorksList';
import { RecordingWithEarnings } from '@/app/ui/organisms/Royalties/TopEarningWorks';

const options = [10, 20, 30, 40, 50];

export type ClearInputHandle = {
  clearInput: () => void;
};
const EarningsWorks = () => {
  const [earningsData, setEarningsData] = useState<RecordingWithEarnings[]>([]);
  const { consumptionReportsBloc, musicalWorksBloc } = useCradle<Cradle>();
  const [currentPagination, setCurrentPagination] = useState<Pagination>({ limit: options[0] });
  const [totalElements, setTotalElements] = useState<number>(0);
  const [titleSearchText, setTitleSearchText] = useState<string>('');

  const titleRef = createRef<ClearInputHandle>();

  async function getRecordingsWithEarnings(pagination: Pagination, recordingTitle?: string) {
    let recordings: PaginatedEntity<Recording> | undefined;

    try {
      recordings = await musicalWorksBloc.getRecordings({
        pagination,
        filters: { name: recordingTitle },
      });
    } catch (error) {
      Sentry.captureException(error);
    }
    if (!recordings) return;
    setCurrentPagination(recordings.pagination);
    setTotalElements(recordings.pagination.totalElements);
    const recordingIds = recordings.data.map((recording) => recording.id);

    let earnings: TotalEarnings[] = [];
    try {
      earnings = await consumptionReportsBloc.getEarningsByRecordings(undefined, {
        recordingIds,
      });
    } catch (error) {
      Sentry.captureException(error);
    }

    const earningsRecordings: RecordingWithEarnings[] = [];
    try {
      await Promise.all(
        earnings.map(async (earning) => {
          const recording = recordings?.data.find((earn) => earn.id === earning.recordingId);
          if (!recording) return;
          earningsRecordings.push({
            earnings: earning,
            recording,
          });
        }),
      );
    } catch (error) {
      // TODO error
    }
    setEarningsData(earningsRecordings);
  }

  function clearSearch() {
    setTitleSearchText('');
    titleRef.current?.clearInput();
    try {
      getRecordingsWithEarnings(currentPagination);
    } catch (error) {
      toast.error('An error ocurred while loading your earnings');
    }
  }

  const jumpToPage = async (page: number) => {
    const pag = {
      limit: currentPagination.limit,
      offset: (currentPagination.limit ?? options[0]) * (page - 1),
    };
    try {
      getRecordingsWithEarnings(pag);
    } catch (error) {
      toast.error('An error ocurred while loading your earnings');
    }
  };

  const onSelectElementsPerPage = async (nElements: number) => {
    if (!earningsData) {
      return;
    }

    const newPagination = { offset: 0, limit: nElements };
    setCurrentPagination(newPagination);
    getRecordingsWithEarnings(newPagination);
  };

  useEffect(() => {
    try {
      getRecordingsWithEarnings({ limit: currentPagination.limit });
    } catch (error) {
      toast.error('An error ocurred while loading your earnings');
    }
  }, []);

  return (
    <Flex.Container padding="3rem" flexDirection="column">
      <Flex.Container>
        <Text.PageTitle color={Colors.purple}>{translate('NAVBAR.EARNINGS')}</Text.PageTitle>
        <Text.PageTitle color={Colors.purple}>
          &nbsp;/ {translate('NAVBAR.ROYALTIES')}
        </Text.PageTitle>
        <Text.Title3>&nbsp;/ {translate('EARNINGS.ROYALTIES_BY_WORK')}</Text.Title3>
      </Flex.Container>
      <Flex.Container flexDirection="column" className="mt-5" gap="1rem">
        <Flex.Container gap="1.5rem" style={{ width: '33%' }}>
          <Flex.Item flex={2}>
            <InputSearch
              ref={titleRef}
              placeholder={translate('WORKS.SEARCH_BY_TITLE')}
              clearInput={titleSearchText.length > 0}
              onClear={() => clearSearch()}
              onChange={(text) => setTitleSearchText(text)}
              submit={() => getRecordingsWithEarnings(currentPagination, titleSearchText)}
            />
          </Flex.Item>
          <Flex.Item flex={1}>
            <Button
              $full
              $size="md"
              $variant="primary"
              onClick={() => getRecordingsWithEarnings(currentPagination, titleSearchText)}
            >
              <Text.SectionSubtitleBold>{translate('WORKS.SEARCH')}</Text.SectionSubtitleBold>
            </Button>
          </Flex.Item>
        </Flex.Container>
        {earningsData !== undefined && (
          <>
            <ElementsPerPageSelectorClick
              position="right"
              totalElements={totalElements}
              options={options}
              defaultElementsPerPage={options[0]}
              onChange={(p) => onSelectElementsPerPage(p)}
              elementType={translate('FILTERS.WORKS')}
            />
            <EarningWorksList earningsData={earningsData} />
            <PaginationComponent
              totalElements={totalElements}
              pagination={currentPagination}
              jumpToPage={jumpToPage}
              maxDisplayedPages={10}
            />
          </>
        )}
      </Flex.Container>
    </Flex.Container>
  );
};

export default EarningsWorks;

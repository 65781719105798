import Colors from '@/app/styles/Colors';

export enum BulletStatus {
  Pending = 'pending',
  Distributed = 'distributed',
  Rejected = 'rejected',
}
interface StatusBulletProps {
  status: BulletStatus;
}

export const StatusBullet = (props: StatusBulletProps) => {
  const { status } = props;
  const colorMap = {
    pending: Colors.statusBlue,
    distributed: Colors.statusGreen,
    rejected: Colors.statusRed,
  };

  const color = colorMap[status] || Colors.grey; // Default to grey if status is not recognized

  const circleStyle = {
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor: color,
    marginRight: '13px',
  };
  return <div style={circleStyle} />;
};

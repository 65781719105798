import { createElement } from 'react';

import spotifyIcon from '@/app/assets/images/icons/spotify-icon.svg';
import deezerIcon from '@/app/assets/images/icons/deezer-icon.svg';
import appleMusicIcon from '@/app/assets/images/icons/apple-music-icon.svg';
import soundCloudIcon from '@/app/assets/images/icons/sound-cloud-icon.svg';
import youtubeIcon from '@/app/assets/images/icons/youtube-icon.svg';
import genericPlatform from '@/app/assets/images/icons/genericPlatform.svg';
import vevoIcon from '@/app/assets/images/icons/vevo.png';
import tiktokIcon from '@/app/assets/images/icons/tiktok.svg';
import beatportIcon from '@/app/assets/images/icons/beatport.svg';
import facebookIcon from '@/app/assets/images/icons/facebook.svg';
import pandoraIcon from '@/app/assets/images/icons/pandora.png';
import tidalIcon from '@/app/assets/images/icons/tidal.png';
import napsterIcon from '@/app/assets/images/icons/napster.svg';
import { Img } from '@/app/ui/atoms';

type ImageProps = React.ComponentProps<typeof Img>;
type ImagePropsNoSrc = Omit<ImageProps, 'src'>;

/* ##### CUSTOM ICONS ####################################################### */

const ImageIcon = (props: ImageProps) => {
  const { width, height, src, className, onClick } = props;

  return createElement(Img, {
    src,
    height: width === undefined && height === undefined ? 32 : height || 'auto',
    width: width || 'auto',
    ...props,
    className: `${className !== undefined ? className : ''}${
      onClick !== undefined ? ' clickable' : ''
    }`,
  });
};

const SpotifyIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: spotifyIcon, ...props });

const DeezerIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: deezerIcon, ...props });

const AppleMusicIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: appleMusicIcon, ...props });

const SoundCloudIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: soundCloudIcon, ...props });

const YoutubeIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: youtubeIcon, ...props });

const TikTokIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: tiktokIcon, ...props });

const VevoIcon = (props: ImagePropsNoSrc) => createElement(ImageIcon, { src: vevoIcon, ...props });

const BeatportIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: beatportIcon, ...props });

const PandoraIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: pandoraIcon, ...props });

const FacebookIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: facebookIcon, ...props });

const TidalIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: tidalIcon, ...props });

const NapsterIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: napsterIcon, ...props });

const DefaultDigitalDistributionIcon = (props: ImagePropsNoSrc) =>
  createElement(ImageIcon, { src: genericPlatform, ...props });

const Icons = {
  SpotifyIcon,
  DeezerIcon,
  AppleMusicIcon,
  SoundCloudIcon,
  YoutubeIcon,
  DefaultDigitalDistributionIcon,
  TikTokIcon,
  VevoIcon,
  BeatportIcon,
  PandoraIcon,
  TidalIcon,
  NapsterIcon,
  FacebookIcon,
};

// type DigitalDistributionIconsType =
//   | 'spotify'
//   | 'deezer'
//   | 'apple-music'
//   | 'sound-cloud'
//   | 'youtube';

interface DigitalDistributionIconsProps {
  icon: string;
  width: number;
  style?: React.CSSProperties;
}

const DigitalDistributionIcons = ({ icon, style, width }: DigitalDistributionIconsProps) => {
  if (icon.toUpperCase() === 'SPOTIFY') return <Icons.SpotifyIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'DEEZER') return <Icons.DeezerIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'APPLE MUSIC')
    return <Icons.AppleMusicIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'SOUNDCLOUD')
    return <Icons.SoundCloudIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'YOUTUBE MUSIC')
    return <Icons.YoutubeIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'VEVO') return <Icons.VevoIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'TIKTOK') return <Icons.TikTokIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'BEATPORT') return <Icons.BeatportIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'TIDAL') return <Icons.TidalIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'FACEBOOK') return <Icons.FacebookIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'NAPSTER') return <Icons.NapsterIcon style={style} width={width} />;
  if (icon.toUpperCase() === 'PANDORA') return <Icons.PandoraIcon style={style} width={width} />;

  return <Icons.DefaultDigitalDistributionIcon style={style} width={width} />;
};

export default DigitalDistributionIcons;

import { Cradle } from '@solo/app-core';
import { useLocation, Navigate } from 'react-router-dom';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

const NotAuthRoute = ({ children }: { children: JSX.Element }) => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const location = useLocation();

  if (authState.kind === 'AuthenticatedAuthState' && authState.user.isUserValidated()) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  return children;
};

export default NotAuthRoute;

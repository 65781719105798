import { MusicalRight, Pagination } from '@solo/app-core';
import { useState } from 'react';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import Colors from '@/app/styles/Colors';
import { ElementsPerPageSelectorClick, Icon } from '@/app/ui/molecules';
import logo from '@/app/assets/images/logo.png';
import PaginationComponent from '@/app/ui/organisms/Pagination/Pagination';
import { dayjs } from '@/app/utils';

// interface StatementsTableProps {
//   statements: Usage[];
// }

interface Statement {
  id: string;
  from: Date;
  to: Date;
  period: string;
  status: string;
  type: MusicalRight;
  amount: number;
}

function generateRandomStatement(id: number): Statement {
  const randomStatus = ['Paid', 'Unpaid', 'Payment pending'];
  const randomType = [MusicalRight.DISTRIBUTION, MusicalRight.PUBLISHING];
  const year = 2022 + Math.floor(id / 12);
  const month = id % 12 === 0 ? 12 : id % 12;
  const startDate = new Date(year, month - 1, 1);
  const endDate = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0);
  const period = `${dayjs(startDate).format('D MMM YYYY')} - ${dayjs(endDate).format(
    'D MMM YYYY',
  )}`;
  return {
    id: id.toString(),
    from: startDate,
    to: endDate,
    period,
    status: randomStatus[Math.floor(Math.random() * randomStatus.length)],
    type: randomType[Math.floor(Math.random() * randomType.length)],
    amount: Math.floor(Math.random() * 1000) + 100, // Amount between 100 y 1000
  };
}

const statementsMock: Statement[] = [];
for (let i = 1; i <= 100; i += 1) {
  statementsMock.push(generateRandomStatement(i));
}

interface StatementListProps {
  statusFilter?: string;
  typeFilter?: string;
  dateFromFilter?: Date;
  dateToFilter?: Date;
}

const options = [10, 20, 30, 40, 50];

const StatementList = (props: StatementListProps) => {
  const { statusFilter, typeFilter, dateFromFilter, dateToFilter } = props;

  const [pagination, setPagination] = useState<Pagination>({ offset: 0, limit: 10 });
  function downloadStatement() {
    const link = document.createElement('a');
    link.href = logo;
    link.setAttribute('download', 'Statement.pdf');
    document.body.appendChild(link);
    link.click();
  }

  const jumpToPage = async (page: number) => {
    const pag: Pagination = {
      ...pagination,
      offset: (pagination.limit || 10) * (page - 1),
    };
    setPagination(pag);
  };

  const selectElementsPerPage = async (nElements: number) => {
    const pag = { ...pagination, limit: nElements };
    setPagination(pag);
  };

  const statementsFiltered = statementsMock.filter((statement) => {
    if (typeFilter && typeFilter !== 'All' && statement.type !== typeFilter) return false;
    if (statusFilter && statusFilter !== 'All' && statement.status !== statusFilter) return false;
    if (dateFromFilter && statement.from.getTime() < dateFromFilter.getTime()) return false;
    if (dateToFilter && statement.to.getTime() >= dateToFilter.getTime()) return false;
    return true;
  });

  const { limit = 10, offset = 0 } = pagination;
  let startSlice = offset;
  if (startSlice > statementsFiltered.length) {
    startSlice = statementsFiltered.length - limit;
  }
  let endSlice = offset + limit;
  if (endSlice > statementsFiltered.length) {
    endSlice = statementsFiltered.length;
  }
  const statementsPaginated = statementsFiltered.slice(startSlice, endSlice);

  return (
    <>
      <ElementsPerPageSelectorClick
        position="right"
        totalElements={statementsFiltered.length}
        options={options}
        defaultElementsPerPage={options[0]}
        onChange={(p) => selectElementsPerPage(p)}
      />
      <Flex.Container className="table" flexDirection="column">
        <Flex.Container gap="16px" className="thead">
          <Flex.Item flex={1} className="th" style={{ paddingLeft: '1rem' }}>
            <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
              ID
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item flex={3} className="th" style={{ paddingLeft: '1rem' }}>
            <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
              {translate('EARNINGS.PERIOD')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item flex={3} className="th">
            <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
              {translate('WORKS.STATUS')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item flex={3} className="th">
            <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
              {translate('WORKS.TYPE')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item flex={3} className="th">
            <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
              {translate('EARNINGS.AMOUNT')}
            </Text.Paragraph>
          </Flex.Item>
          <Flex.Item flex={2} className="th">
            <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
              {translate('ACTIONS.ACTIONS')}
            </Text.Paragraph>
          </Flex.Item>
        </Flex.Container>
        <Flex.Container className="tbody" flexDirection="column">
          {statementsPaginated.map((statement) => (
            <Flex.Container
              key={statement.id}
              className="tr"
              alignItems="center"
              gap="16px"
              style={{ padding: '0.5rem' }}
            >
              <Flex.Item flex={1} className="td" style={{ paddingLeft: '1rem' }}>
                {statement.id}
              </Flex.Item>
              <Flex.Item flex={3} className="td" style={{ paddingLeft: '1rem' }}>
                <Text.Paragraph fontWeight={700}>{statement.period}</Text.Paragraph>
              </Flex.Item>
              <Flex.Item flex={3} className="td">
                {statement.status}
              </Flex.Item>
              <Flex.Item flex={3} className="td">
                {statement.type}
              </Flex.Item>
              <Flex.Item flex={3} className="td">
                {statement.amount}
              </Flex.Item>
              <Flex.Item flex={2} className="td">
                <Button $size="md" $transparent style={{ padding: 0 }}>
                  <Flex.Container
                    gap="0.5rem"
                    alignItems="center"
                    onClick={() => downloadStatement()}
                  >
                    <Icon.DownloadIcon />
                    <Text.Paragraph fontWeight={700} style={{ padding: 0, color: Colors.primary }}>
                      {translate('DOWNLOAD.DOWNLOAD')}
                    </Text.Paragraph>
                  </Flex.Container>
                </Button>
              </Flex.Item>
            </Flex.Container>
          ))}
        </Flex.Container>
      </Flex.Container>
      <PaginationComponent
        totalElements={statementsFiltered.length}
        pagination={{ limit: pagination.limit, offset: startSlice }}
        jumpToPage={jumpToPage}
        maxDisplayedPages={8}
      />
    </>
  );
};

export default StatementList;

import { Outlet, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Cradle } from '@solo/app-core';
import { Flex, Text, Section, CustomNavBar, CustomNavBarItem, RouterLink } from '@/app/ui';

import { translate } from '@/app/i18n';
import { useCradle } from '@/app/contexts';

const WorkPage = (): JSX.Element => {
  const { id } = useParams();
  const { musicalWorksBloc } = useCradle<Cradle>();
  const [musicalReleaseType, setMusicalReleaseType] = useState<string>('');
  const [musicalReleaseTitle, setMusicalReleaseTitle] = useState<string>('');

  useEffect(() => {
    async function getMusicalReleaseType() {
      if (!id) return;
      const musicalRelease = await musicalWorksBloc.getMusicalRelease(id);
      const { name } = musicalRelease;
      const type = translate('WORKS_PAGES.RELEASE_DETAILS');
      // switch (type) {
      //   case 'EP':
      //     translatedType = translate('WORKS.EP_DATA');
      //     break;
      //   case 'SINGLE':
      //     translatedType = translate('WORKS.SINGLE_DATA');
      //     break;
      //   case 'ALBUM':
      //     translatedType = translate('WORKS.ALBUM_DATA');
      //     break;
      //   default:
      // }
      setMusicalReleaseType(type);
      setMusicalReleaseTitle(name);
    }
    getMusicalReleaseType();
  }, []);

  const itemsNavBar: CustomNavBarItem[] = [
    {
      routerLinkTo: '',
      title: musicalReleaseType,
      end: true,
    },
    {
      routerLinkTo: 'tracks',
      title: translate('WORKS_PAGES.TRACKS'),
    },
    {
      routerLinkTo: 'digital-distribution',
      title: translate('WORKS_PAGES.DIGITAL_DISTRIBUTION'),
    },
  ];

  return (
    <>
      <Flex.Container flexDirection="column" gap="3rem" padding="3rem">
        <Flex.Container>
          <RouterLink $transparent $variant="primary" to="/works">
            <Text.Title2 style={{ marginRight: 5 }}>
              {translate('WORKS_PAGES.MY_RELEASES')}
            </Text.Title2>
          </RouterLink>
          <Text.Title2>/ {musicalReleaseTitle}</Text.Title2>
        </Flex.Container>
      </Flex.Container>
      <Flex.Container flexDirection="column" gap="3rem" padding="0 0 0 3rem">
        <CustomNavBar items={itemsNavBar} />
      </Flex.Container>
      <Section>
        <Flex.Container flexDirection="column" gap="3rem" padding="3rem" style={{ width: '100%' }}>
          <Flex.Item>
            <Outlet />
          </Flex.Item>
        </Flex.Container>
      </Section>
    </>
  );
};

export default WorkPage;

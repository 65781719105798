import {
  Cradle,
  Recording,
  RecordingId,
  CreateRecordingProps,
  UpdateRecordingProps,
} from '@solo/app-core';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { translate } from '@/app/i18n';
import { Button, Section, Flex, Text } from '@/app/ui/atoms';
import RecordingTableComponent from './RecordingTableComponent/RecordingTableComponent';
import { useCradle } from '@/app/contexts';
import { Modal } from '@/app/ui/molecules';
import { useBlocState } from '@/app/hooks';
import RecordingForm from '@/app/ui/organisms/RecordingForm/RecordingForm';

const TracksComponent = () => {
  const { id } = useParams();
  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);

  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [addingRecording, setAddingRecording] = useState<boolean>(false);
  const [deletingRecording, setDeletingRecording] = useState<Recording | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [audioFile, setAudioFile] = useState<File | undefined>();

  useEffect(() => {
    const getRecordings = async () => {
      if (id === undefined) {
        return;
      }
      try {
        const musicalRelease = await musicalWorksBloc.getMusicalRelease(id);
        const musicalReleaseRecordings = musicalRelease.recordings;
        if (!musicalReleaseRecordings) return;
        const releaseRecordings: Recording[] = [];
        await Promise.all(
          musicalReleaseRecordings.map(async (recording) => {
            const rec = await musicalWorksBloc.getRecording(recording.id);
            releaseRecordings.push(rec);
          }),
        );
        setRecordings(releaseRecordings);
      } catch (error) {
        // TODO handling error
      }
    };
    getRecordings();
  }, [musicalWorksState]);

  async function createRecording(recording: CreateRecordingProps) {
    let recordingResponse: Recording | undefined;
    if (!id) return;
    // if (!id || !audioFile) return;
    // let resourceId: FileId | undefined;
    // try {
    //   resourceId = await musicalWorksBloc.uploadAudio(audioFile);
    // } catch (error) {
    //   toast.error(translate('TOAST.CHANGES_ERROR'));
    // }

    try {
      recordingResponse = await musicalWorksBloc.createRecording(
        {
          ...recording,
          resourceId: '',
          artistSpotifyId: 'artistSpotifyId',
          artistAppleId: 'artistAppleId',
        } as CreateRecordingProps,
        id,
      );
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }

    if (!recordingResponse) return;
    // if (!recordingResponse || !audioFile) return;

    musicalWorksBloc.loadRecordings();
  }

  async function updateRecording(recording: UpdateRecordingProps, recordingId: RecordingId) {
    try {
      await musicalWorksBloc.updateRecording(recordingId, recording);
      toast.success(translate('TOAST.CHANGES_SAVED'));
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }

    musicalWorksBloc.loadRecordings();
  }

  function deleteRecording() {
    if (!id || !deletingRecording) return;
    musicalWorksBloc.removeRecordingsFromMusicalRelease(id, [deletingRecording.id]);
    setDeletingRecording(undefined);
    musicalWorksBloc.loadRecordings();
  }

  async function reorderRecordings(recordingId: string, indexTo: number) {
    if (id === undefined) return;
    await musicalWorksBloc.reorderReleaseRecordings(id, recordingId, indexTo);
    musicalWorksBloc.loadRecordings();
  }

  return (
    <>
      <Section>
        <Flex.Container flex={1} justifyContent="flex-end">
          <Button
            $size="md"
            $outline
            $transparent
            $variant="primary"
            onClick={() => {
              setAddingRecording(true);
            }}
          >
            <Text.Paragraph style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.ADD_NEW_TRACK')}
            </Text.Paragraph>
          </Button>
        </Flex.Container>
      </Section>
      <Section my={10}>
        {recordings && (
          <RecordingTableComponent
            deleteRecording={(recording) => setDeletingRecording(recording)}
            createRecording={(recording) => createRecording(recording)}
            updateRecording={(recording, recordingId) => updateRecording(recording, recordingId)}
            setNewAudio={(audio) => setAudioFile(audio)}
            changeOrder={(recordingId, indexTo) => reorderRecordings(recordingId, indexTo)}
            recordings={recordings}
          />
        )}
      </Section>
      <Modal
        isOpen={addingRecording}
        onClose={() => setAddingRecording(false)}
        closeable
        position="top"
        size="lg"
        backdropCloseable
      >
        <Flex.Container flexDirection="column" gap="40px" padding="30px 90px 20px 90px">
          <RecordingForm
            closeForm={() => setAddingRecording(false)}
            createRecording={(recording) => createRecording(recording)}
            updateRecording={(recording, recordingId) => updateRecording(recording, recordingId)}
            setNewAudio={(audio) => setAudioFile(audio)}
          />
        </Flex.Container>
      </Modal>
      <Modal
        isOpen={deletingRecording !== undefined}
        onClose={() => setDeletingRecording(undefined)}
        closeable
        position="center"
        size="sm"
        backdropCloseable
      >
        <Flex.Container flexDirection="column" gap="40px" padding="10px 20px">
          <Text.LabelMedium>
            {translate('TRACKS.SURE_DELETE')} {deletingRecording?.title} ?
          </Text.LabelMedium>
          <Flex.Container style={{ marginLeft: 'auto' }} gap="20px">
            <Button
              $transparent
              $variant="primary"
              onClick={() => {
                setDeletingRecording(undefined);
              }}
            >
              <Text.SectionSubtitle>{translate('PROFILE_INFO.CANCEL')}</Text.SectionSubtitle>
            </Button>

            <Button
              $size="md"
              $variant="primary"
              style={{ width: '290px' }}
              onClick={() => {
                deleteRecording();
              }}
            >
              <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                {translate('BUTTON.DELETE')}
              </Text.SectionSubtitle>
            </Button>
          </Flex.Container>
        </Flex.Container>
      </Modal>
    </>
  );
};

export default TracksComponent;

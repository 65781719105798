import styled, { css } from 'styled-components/macro';
import Colors from '@/app/styles/Colors';

interface InputProps {
  disabled?: boolean;
}

const commonCSS = css`
  background-color: ${Colors.white};
  border: 1px solid ${Colors.borderInput};
  padding: 1rem;
  &:focus-visible,
  &:focus {
    outline: none;
    border-color: ${Colors.primary};
  }
`;

const TextInput = styled.input.attrs<InputProps>({
  type: 'text',
})`
  ${commonCSS}
  width:100%;
  background-color: ${(props) => `${props.disabled ? Colors.borderInput : ''}`};
`;

const NumericInput = styled.input.attrs({
  type: 'number',
})`
  ${commonCSS}
  width:100%;
`;

const PasswordInput = styled.input.attrs({
  type: 'password',
})`
  ${commonCSS}
  width:100%;
`;

const FileInput = styled.input.attrs({
  type: 'file',
})`
  ${commonCSS}
  width:100%;
`;

const Checkbox = styled.input.attrs({
  type: 'checkbox',
})`
  ${commonCSS}
  width: 16px;
  height: 16px;
`;

const RadioInput = styled.input.attrs({
  type: 'radio',
})`
  width: ${(props) => props.width || 'auto'};
  height: '12px';
  &:after {
    background-color: ${Colors.purple};
  }
`;

const Input = {
  Text: TextInput,
  Numeric: NumericInput,
  Password: PasswordInput,
  Checkbox,
  Radio: RadioInput,
  File: FileInput,
};

export default Input;

import { Block, Flex, Text } from '@/app/ui/atoms';
import { InputTextAutocomplete } from '@/app/ui/molecules';
import { translate } from '@/app/i18n';

interface ElementsPerPageProps {
  options: number[];
  position: 'left' | 'right';
  totalElements: number;
  defaultElementsPerPage: number;
  elementType?: string;
  onChange: (nElements: number) => void;
}

const ElementsPerPageClick = ({
  options,
  position,
  totalElements,
  defaultElementsPerPage,
  elementType = translate('FILTERS.ITEMS'),
  onChange,
}: ElementsPerPageProps) => {
  const handleOnChange = (pages: number) => {
    onChange(pages);
  };
  return (
    <Flex.Container
      gap="1rem"
      alignItems="center"
      justifyContent={position === 'left' ? 'flex-start' : 'flex-end'}
    >
      <Text.Span>{translate('FILTERS.SEE')}</Text.Span>
      <Block style={{ width: 'auto' }}>
        <InputTextAutocomplete
          placeholder="Status"
          items={options.map((stat) => ({ value: stat.toFixed(), label: stat.toFixed() }))}
          onChange={(item) => handleOnChange(parseInt(item, 10))}
          isSearchable={false}
          defaultOption={{
            value: defaultElementsPerPage.toFixed(),
            label: defaultElementsPerPage.toFixed(),
          }}
        />
      </Block>
      <Text.Span>
        {translate('FILTERS.OF')} {totalElements} {elementType}
      </Text.Span>
    </Flex.Container>
  );
};

export default ElementsPerPageClick;

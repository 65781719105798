const Colors = {
  transparent: 'transparent',
  white: '#FFFFFF',
  whitePurple: '#FAF5FF',
  blue: '#4179F1',
  blueDarker: '#2d52a0',
  purple: '#8d00ff',
  purpleLighter: '#CA95FD',
  purpleDarker: '#693194',
  green: '#71faa2',
  greenSolid: '#09b34a',
  greenDarker: '#62b573',
  red: '#E50045',
  redDarker: '#a10232',
  black: '#222222',
  notSoBlack: '#111111',
  grey: '#696969',
  grey2: '#464646',
  grey3: '#CBCBCB',
  grey4: '#282828',
  grey5: '#E1E1E1',
  greyLight: '#ADADAD',
  greyLine: '#777777',
  yellow1: '#d7bd69',
  yellow2: '#ffeeba',
  yellow3: '#654c01',
  yellow: '',
  yellowDarker: '',
  primary: '#8d00ff',
  primaryDarker: '',
  secondary: '',
  secondaryDarker: '',
  placeholder: '#999999',
  disabledButton: '#B9B9B9',
  disabledInput: '#F0F0F0',
  backgroundSidebar: '#FAFAFA',
  borderInput: '#E5E5E5',
  boxShadowColor: '#5f5f5f',
  blackWithOpacity: 'rgba(34, 34, 34, 0.85)',
  pink: '#FF0DD5',
  statusRed: '#E51C00',
  statusBlue: '#0071E9',
  statusGreen: '#09B34A',
  selectBlue: '#3384FF',
};

Colors.primary = Colors.purple;
Colors.primaryDarker = Colors.purpleDarker;
Colors.secondary = Colors.green;
Colors.secondaryDarker = Colors.greenDarker;
Colors.yellow = Colors.yellow1;
Colors.yellowDarker = Colors.yellow3;

export default Colors;

// import { useState } from 'react';
import { useEffect, useState } from 'react';
import {
  Contributor,
  CreateMusicalReleaseProps,
  MusicalRelease,
  UpdateMusicalReleaseProps,
} from '@solo/app-core';
import { toast } from 'react-toastify';
import {
  Flex,
  Text,
  Button,
  Icon,
  InputTextAutocomplete,
  InputDateWithLabel,
  FileUploadComponent,
  ArtistsTableComponent,
} from '@/app/ui';
import { translate } from '@/app/i18n';
import InputWithLabel from '@/app/ui/molecules/InputWithLabel';
import Colors from '@/app/styles/Colors';

export const languages = [
  'Spanish',
  'English',
  'French',
  'Italian',
  'German',
  'Rusian',
  'Japanese',
  'Chinese',
  'Arabic',
]; // TODO back
export const genres = [
  'Pop',
  'Rock',
  'Rap',
  'R&B',
  'Blues',
  'Jazz',
  'Electronic',
  'Hip Hop',
  'Opera',
  'Soundtrack',
];
const roles = [
  'Remixer',
  'Singer',
  'Writer',
  'Featuring',
  'With',
  'Performer',
  'Drums',
  'Translator',
  'Adaptor',
  'Actor',
];
export const labels = ['Sony Music Entertainment', 'Warner Muic Group'];

export interface MusicalReleaseDetailsFromProps {
  musicalRelease?: MusicalRelease;
  detailsFieldsMissing?: boolean;
  closeModal?: () => void;
  onChange?: (musicalRelease: CreateMusicalReleaseProps | UpdateMusicalReleaseProps) => void;
  saveDetails?: (musicalRelease: CreateMusicalReleaseProps | UpdateMusicalReleaseProps) => void;
  setNewArtwork: (artwork: File) => void;
}

const MusicalReleaseDetailsForm = (props: MusicalReleaseDetailsFromProps) => {
  const { musicalRelease, detailsFieldsMissing, closeModal, onChange, setNewArtwork, saveDetails } =
    props;

  const [musicalReleaseDetails, setMusicalReleaseDetails] = useState<UpdateMusicalReleaseProps>({
    ...musicalRelease,
  });

  const [fieldsMissing, setFieldsMissing] = useState<boolean>(detailsFieldsMissing || false);

  const [otherArtist, setOtherArtist] = useState<string>();
  const [otherArtistRole, setOtherArtistRole] = useState<number>();

  const [artwork, setArtowork] = useState<File | undefined>();

  const type = translate('WORKS_PAGES.RELEASE_DETAILS');
  const title = translate('WORKS.RELEASE_TITLE');

  const years = ['2024', '2023', '2022', '2021', '2020'];

  // switch (musicalRelease?.type || releaseType) {
  //   case 'ALBUM':
  //     type = translate('WORKS_PAGES.ALBUM_DETAILS');
  //     title = translate('WORKS.ALBUM_TITLE');
  //     break;
  //   case 'SINGLE':
  //     type = translate('WORKS.SINGLE_DETAILS');
  //     title = translate('WORKS.SINGLE_TITLE');
  //     break;
  //   default:
  //     type = translate('WORKS.EP_DETAILS');
  //     title = translate('WORKS.EP_TITLE');
  // }

  function deleteArtist(artist: Contributor) {
    const artistIndex = musicalReleaseDetails.contributors?.findIndex(
      (a) => a.artist.name === artist.artist.name,
    );
    if (artistIndex === undefined || !musicalReleaseDetails.contributors) return;
    let artists: Contributor[] | undefined;
    if (musicalReleaseDetails.contributors.length > 1) {
      artists = musicalReleaseDetails.contributors?.splice(artistIndex - 1, 1);
    }
    setMusicalReleaseDetails({
      ...musicalReleaseDetails,
      contributors: artists,
    });
  }

  function addNewArtist() {
    if (!musicalReleaseDetails || !otherArtist || otherArtistRole === undefined) return;
    let otherArtists = musicalReleaseDetails.contributors;
    const newArt = { artist: { name: otherArtist }, roleId: otherArtistRole };
    if (!otherArtists) {
      otherArtists = [newArt];
    } else {
      otherArtists.push(newArt);
    }
    setMusicalReleaseDetails({ ...musicalReleaseDetails, contributors: otherArtists });
    setOtherArtist(undefined);
  }

  function checkMandatoryFields() {
    return (
      musicalReleaseDetails.name !== '' &&
      musicalReleaseDetails.releaseDate &&
      musicalReleaseDetails.artistName &&
      musicalReleaseDetails.artistName !== '' &&
      musicalReleaseDetails.releaseDate !== '' &&
      musicalReleaseDetails.languageId !== undefined &&
      musicalReleaseDetails.primaryMusicStyleId !== undefined
    );
  }

  function validateDetailsForm(): CreateMusicalReleaseProps | UpdateMusicalReleaseProps {
    if (checkMandatoryFields()) {
      const detailsData = {
        ...musicalReleaseDetails,
        productCopyrightYear: musicalReleaseDetails.copyrightP || parseInt(years[0], 10),
        soundCopyrightYear: musicalReleaseDetails.copyrightC || parseInt(years[0], 10),
      };
      return detailsData;
    }
    throw new Error('All mandatory fields must be filled!');
  }

  async function saveChanges() {
    if (!saveDetails) return;
    try {
      const detailsInfo = await validateDetailsForm();
      saveDetails(detailsInfo);
      if (closeModal) closeModal();
    } catch (error) {
      if (error instanceof Error) toast.error(error.message);
      setFieldsMissing(true);
    }
  }

  useEffect(() => {
    if (onChange) onChange(musicalReleaseDetails);
  }, [musicalReleaseDetails]);

  useEffect(() => {
    if (artwork) setNewArtwork(artwork);
  }, [artwork]);

  useEffect(() => {
    if (detailsFieldsMissing) setFieldsMissing(detailsFieldsMissing);
  }, [detailsFieldsMissing]);

  return (
    <Flex.Container flexDirection="column" gap="4rem" style={{ width: '100%' }}>
      <Flex.Container gap="25px" flexDirection="column">
        <Text.SubtitleBold>{type}</Text.SubtitleBold>
        <InputWithLabel
          placeholder={title}
          label={`${title}*`}
          value={musicalReleaseDetails.name}
          onChange={(t) => setMusicalReleaseDetails({ ...musicalReleaseDetails, name: t })}
          mandatoryError={fieldsMissing && !musicalReleaseDetails.name}
        />
        <InputWithLabel
          label={translate('WORKS.MAIN_ARTIST')}
          placeholder={translate('WORKS.MAIN_ARTIST')}
          onChange={(name) =>
            setMusicalReleaseDetails({ ...musicalReleaseDetails, artistName: name })
          }
          value={musicalReleaseDetails.artistName}
        />
        <Flex.Container flexDirection="column" gap="25px">
          <Flex.Container gap="16px" style={{ width: '100%' }} alignItems="flex-end">
            <Flex.Item flex={2}>
              <InputWithLabel
                placeholder={translate('WORKS.OTHER_ARTISTS')}
                label={translate('WORKS.OTHER_ARTISTS')}
                onChange={(name) => setOtherArtist(name)}
                value={otherArtist}
              />
            </Flex.Item>
            <Flex.Item flex={2}>
              <InputTextAutocomplete
                label={translate('TRACKS.ROLE')}
                placeholder={translate('WORKS_PAGES.TYPES_OF_ROLE')}
                items={Array.from(roles, (role: string) => ({
                  value: role,
                  label: role,
                }))}
                onChange={(role) => setOtherArtistRole(roles.findIndex((r) => r === role))}
              />
            </Flex.Item>
            <Flex.Container flex={1}>
              <Button
                $size="md"
                $variant="primary"
                style={{ height: '38px', width: '170px' }}
                onClick={() => addNewArtist()}
              >
                <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                  {translate('BUTTON.ADD')}
                </Text.SectionSubtitle>
              </Button>
            </Flex.Container>
          </Flex.Container>
          <Flex.Container>
            {musicalReleaseDetails.contributors && musicalReleaseDetails.contributors.length > 0 && (
              <Flex.Item flex={1}>
                <ArtistsTableComponent
                  artists={musicalReleaseDetails.contributors}
                  deleteArtist={(artist) => deleteArtist(artist)}
                />
              </Flex.Item>
            )}
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputDateWithLabel
              defaultValue={
                musicalReleaseDetails.releaseDate
                  ? new Date(musicalReleaseDetails.releaseDate)
                  : undefined
              }
              label={translate('WORKS.RELEASE_DATE')}
              onChange={(releaseDate) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  releaseDate: releaseDate.toDateString(),
                })
              }
              mandatoryError={fieldsMissing && !musicalReleaseDetails.releaseDate}
            />
          </Flex.Container>
          {/* <Flex.Container flex={1}>
            <TimePickerWithLabel
              defaultValue={
                musicalReleaseDetails.releaseTime
                  ? dayjs(
                      `01/01/2023 ${musicalReleaseDetails.releaseTime}:00`,
                      'DD-MM-YYYY HH:mm:ss',
                    )
                  : undefined
              }
              label={translate('WORKS.RELEASE_TIME')}
              onChange={(time) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, releaseTime: time })
              }
              mandatoryError={fieldsMissing && !musicalReleaseDetails.releaseDate}
            />
          </Flex.Container> */}
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('WORKS.LANGUAGE')}
              tooltip
              items={Array.from(languages, (language: string) => ({
                value: language,
                label: language,
              }))}
              defaultOption={
                musicalReleaseDetails.languageId !== undefined
                  ? {
                      value: musicalReleaseDetails.languageId.toString(),
                      label: languages[musicalReleaseDetails.languageId],
                    }
                  : undefined
              }
              onChange={(language) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  languageId: languages.indexOf(language),
                })
              }
              mandatoryError={fieldsMissing && musicalReleaseDetails.languageId === undefined}
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              placeholder={translate('WORKS.RECORD_LABEL')}
              label={translate('WORKS.RECORD_LABEL')}
              items={Array.from(labels, (label: string) => ({
                value: label,
                label,
              }))}
              defaultOption={
                musicalReleaseDetails.labelId !== undefined
                  ? {
                      value: musicalReleaseDetails.labelId.toString(),
                      label: labels[musicalReleaseDetails.labelId],
                    }
                  : undefined
              }
              onChange={(recordLabel) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  labelId: labels.indexOf(recordLabel),
                })
              }
            />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('WORKS.PRIMARY_GENRE')}
              placeholder={translate('WORKS.CHOOSE_GENRE')}
              defaultOption={
                musicalReleaseDetails.primaryMusicStyleId !== undefined
                  ? {
                      value: genres[musicalReleaseDetails.primaryMusicStyleId],
                      label: genres[musicalReleaseDetails.primaryMusicStyleId],
                    }
                  : undefined
              }
              tooltip
              items={Array.from(genres, (genre) => ({ value: genre, label: genre }))}
              onChange={(primaryGenre) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  primaryMusicStyleId: genres.indexOf(primaryGenre),
                })
              }
              mandatoryError={
                fieldsMissing && musicalReleaseDetails.primaryMusicStyleId === undefined
              }
            />
          </Flex.Container>
          {/* <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('WORKS.SECONDARY_GENRE')}
              placeholder={translate('WORKS.CHOOSE_GENRE')}
              defaultOption={
                musicalReleaseDetails?.secondaryGenre
                  ? {
                      value: musicalReleaseDetails.secondaryGenre,
                      label: musicalReleaseDetails.secondaryGenre,
                    }
                  : undefined
              }
              tooltip
              items={Array.from(genres, (genre) => ({ value: genre, label: genre }))}
              onChange={(secondaryGenre) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  secondaryGenre,
                })
              }
            />
          </Flex.Container> */}
        </Flex.Container>
      </Flex.Container>
      <Flex.Container gap="25px" flexDirection="column">
        <Text.SubtitleBold>
          {translate('WORKS.ARTWORK')}
          <Text.LabelSmall style={{ color: Colors.grey, marginLeft: '12px' }}>
            {translate('WORKS.ARTWORK_VALID')}
          </Text.LabelSmall>
        </Text.SubtitleBold>
        <FileUploadComponent
          type="image"
          onChange={(artworkFile) => setArtowork(artworkFile)}
          existingFile={musicalReleaseDetails.resourceId}
        />
      </Flex.Container>
      <Flex.Container flexDirection="column" gap="15px">
        <Flex.Container alignItems="center" gap="1rem">
          <Text.SubtitleBold>UPC / EAN</Text.SubtitleBold>
          <Icon.InfoIcon />
        </Flex.Container>
        <Text.LabelMedium>{translate('WORKS.UPC_INFO')}</Text.LabelMedium>
        <Flex.Container style={{ width: '50%' }}>
          <InputWithLabel
            type="numeric"
            placeholder="UPC / EAN"
            label="UPC / EAN"
            tooltip
            onChange={(upc) => setMusicalReleaseDetails({ ...musicalReleaseDetails, upc })}
            value={musicalReleaseDetails.upc}
          />
        </Flex.Container>
      </Flex.Container>
      <Flex.Container gap="25px" flexDirection="column">
        <Text.SubtitleBold>Copyright</Text.SubtitleBold>
        <Flex.Container gap="16px" style={{ width: '100%' }}>
          <Flex.Container flex={1}>
            <InputWithLabel
              label={translate('WORKS.PRODUCT_COPYRIGHT')}
              placeholder={translate('WORKS.PRODUCT_COPYRIGHT')}
              tooltip
              value={musicalReleaseDetails.copyrightP}
              onChange={(copyrightP) =>
                setMusicalReleaseDetails({ ...musicalReleaseDetails, copyrightP })
              }
            />
          </Flex.Container>
          {/* <Flex.Container style={{ width: '50%' }}>
            <InputTextAutocomplete
              placeholder={musicalReleaseDetails.productCopyrightYear?.toString()}
              label={translate('WORKS.PRODUCT_COPYRIGHT_YEAR')}
              defaultOption={
                musicalReleaseDetails.productCopyrightYear
                  ? {
                      value: musicalReleaseDetails.productCopyrightYear.toString(),
                      label: musicalReleaseDetails.productCopyrightYear.toString(),
                    }
                  : {
                      value: '2022',
                      label: '2022',
                    }
              }
              tooltip
              items={Array.from(years, (year) => ({
                value: year,
                label: year,
              }))}
              onChange={(productCopyrightYear) =>
                setMusicalReleaseDetails({
                  ...musicalReleaseDetails,
                  productCopyrightYear: parseInt(productCopyrightYear, 10),
                })
              }
            />
          </Flex.Container> */}
        </Flex.Container>
      </Flex.Container>
      <Flex.Container gap="16px" style={{ width: '100%' }}>
        <Flex.Container flex={1}>
          <InputWithLabel
            value={musicalReleaseDetails.copyrightC}
            label={translate('WORKS.SOUND_RECORDING_COPYRIGHT')}
            placeholder={translate('WORKS.SOUND_RECORDING_COPYRIGHT')}
            tooltip
            onChange={(copyrightC) =>
              setMusicalReleaseDetails({ ...musicalReleaseDetails, copyrightC })
            }
          />
        </Flex.Container>
        {/* <Flex.Container style={{ width: '50%' }}>
          <InputTextAutocomplete
            defaultOption={
              musicalReleaseDetails.soundCopyrightYear
                ? {
                    value: musicalReleaseDetails.soundCopyrightYear.toString(),
                    label: musicalReleaseDetails.soundCopyrightYear.toString(),
                  }
                : {
                    value: '2022',
                    label: '2022',
                  }
            }
            label={translate('WORKS.SOUND_RECORDING_COPYRIGHT_YEAR')}
            tooltip
            items={Array.from(years, (year) => ({ value: year, label: year }))}
            onChange={(soundCopyrightYear) =>
              setMusicalReleaseDetails({
                ...musicalReleaseDetails,
                soundCopyrightYear: parseInt(soundCopyrightYear, 10),
              })
            }
          />
        </Flex.Container> */}
      </Flex.Container>
      {closeModal && musicalRelease && (
        <Flex.Container style={{ marginLeft: 'auto' }} gap="20px">
          <Button
            $transparent
            $variant="primary"
            onClick={() => {
              closeModal();
            }}
          >
            <Text.SectionSubtitle>{translate('PROFILE_INFO.CANCEL')}</Text.SectionSubtitle>
          </Button>

          <Button
            $size="md"
            $variant="primary"
            style={{ width: '290px' }}
            onClick={() => {
              saveChanges();
            }}
          >
            <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
              {translate('PROFILE_INFO.SAVE')}
            </Text.SectionSubtitle>
          </Button>
        </Flex.Container>
      )}
    </Flex.Container>
  );
};

export default MusicalReleaseDetailsForm;

import { Distribution, Cradle, DigitalPlatform, DigitalPlatformId } from '@solo/app-core';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useCradle } from '@/app/contexts';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { Section, Flex, Text, Input, Button } from '@/app/ui/atoms';
import { DigitalDistributionIcons, Modal, MusicalReleaseDistributionForm } from '@/app/ui';
import { useBlocState } from '@/app/hooks';
import { MusicalReleaseDistributionData } from '@/app/ui/organisms/MusicalReleaseDistributionForm/MusicalReleaseDistributionForm';

interface DigitalDistribution {
  distribution: Distribution;
  digitalPlatform?: DigitalPlatform;
}

const DigitalDistributionComponent = () => {
  const { id } = useParams();
  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionBlocState = useBlocState(digitalDistributionBloc);
  const [digitalDistributions, setDigitalDistributions] = useState<DigitalDistribution[]>([]);
  const [editDistribution, setEditDistribution] = useState<boolean>(false);
  const [selectedPlatforms, setSelectedPlatforms] = useState<DigitalPlatformId[]>([]);

  async function loadDistribution() {
    if (digitalDistributionBlocState.kind !== 'LoadedDigitalDistributionState' || !id) return;
    const distributions = await digitalDistributionBloc.getDistributions({
      musicalReleaseId: id,
    });
    const dist: DigitalDistribution[] = distributions.map((distribution) => {
      const digitalPlatform = digitalDistributionBlocState.platforms.find(
        (p) => p.id === distribution.digitalPlatformId,
      );
      return { distribution, digitalPlatform };
    });
    setDigitalDistributions(dist);
  }

  async function saveDistributions(updatedDistributions: MusicalReleaseDistributionData) {
    if (!digitalDistributions || !id) return;
    updatedDistributions.newDistributions.forEach(async (distribution) => {
      await digitalDistributionBloc.createDistribution(id, distribution);
    });
    updatedDistributions.updatedDistributions.forEach(async (distribution) => {
      await digitalDistributionBloc.updateDistribution(
        distribution.distributionId,
        distribution.props,
      );
    });
    updatedDistributions.deletedDistributions.forEach(async (deletedId) => {
      await digitalDistributionBloc.deleteDistribution(deletedId);
    });

    loadDistribution();
  }

  async function checkPlatform(platformId: DigitalPlatformId | undefined) {
    if (!platformId) return;
    let auxSelectedPlatforms = [...selectedPlatforms];
    if (selectedPlatforms.includes(platformId)) {
      auxSelectedPlatforms = auxSelectedPlatforms.filter((plt) => plt !== platformId);
    } else {
      auxSelectedPlatforms.push(platformId);
    }
    setSelectedPlatforms(auxSelectedPlatforms);
  }

  async function checkAllPlatforms() {
    if (selectedPlatforms.length === digitalDistributions.length) {
      setSelectedPlatforms([]);
    } else {
      const allPlatforms = digitalDistributions.map((dd) => dd.digitalPlatform?.id || 0);
      setSelectedPlatforms(allPlatforms);
    }
  }

  useEffect(() => {
    loadDistribution();
  }, []);

  return (
    <Section>
      <Flex.Container flex={1} className="table" flexDirection="column" gap="3rem">
        <Flex.Container gap="3rem">
          <Text.BiggerBold>{translate('DIGITAL_DISTRIBUTION.PLATFORM')}</Text.BiggerBold>
          <Button $transparent $variant="primary" onClick={() => setEditDistribution(true)}>
            <Text.SectionSubtitle>
              {translate('WORKS_PAGES.EDIT_DISTRIBUTION')}
            </Text.SectionSubtitle>
          </Button>
        </Flex.Container>
        <Flex.Container flexDirection="column">
          <Flex.Container flex={1} className="thead">
            <Flex.Container flex={3} className="th" alignItems="center">
              <Input.Checkbox style={{ marginRight: 15 }} onChange={() => checkAllPlatforms()} />
              <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
                {translate('DIGITAL_DISTRIBUTION.PLATFORM')}
              </Text.Paragraph>
            </Flex.Container>
            {/* <Flex.Item flex={2} className="th">
            {translate('DIGITAL_DISTRIBUTION.DATE')}
          </Flex.Item> */}
            <Flex.Item flex={4} className="th">
              {translate('DIGITAL_DISTRIBUTION.STATUS')}
            </Flex.Item>
            <Flex.Item flex={2} className="th">
              {translate('WORKS.RELEASED_AT')}
            </Flex.Item>
            <Flex.Item flex={2} className="th">
              {translate('ACTIONS.ACTIONS')}
            </Flex.Item>
          </Flex.Container>
          <Flex.Container className="tbody" flexDirection="column">
            {digitalDistributions?.map((digitalDs) => (
              <Flex.Container key={digitalDs.distribution.id} className="tr" alignItems="center">
                <Flex.Container className="td" flex={3} alignItems="center">
                  <Input.Checkbox
                    style={{ marginRight: 15 }}
                    onChange={() => checkPlatform(digitalDs.digitalPlatform?.id)}
                    checked={
                      digitalDs.digitalPlatform
                        ? selectedPlatforms.includes(digitalDs.digitalPlatform.id)
                        : false
                    }
                  />
                  <DigitalDistributionIcons
                    style={{ marginRight: 15 }}
                    width={25}
                    icon={digitalDs.digitalPlatform?.name || ''} // TODO icon not found
                  />
                  {digitalDs.digitalPlatform?.name || 'Platform not found'}
                </Flex.Container>
                {/* <Flex.Item flex={2} className="td">
                {platforms[digitalDs.id].date}
              </Flex.Item> */}
                <Flex.Item flex={4} className="td">
                  <Text.LabelMedium style={{ textTransform: 'capitalize' }}>
                    {digitalDs.distribution.status?.toLowerCase()}
                  </Text.LabelMedium>
                </Flex.Item>
                <Flex.Item flex={2} className="td">
                  <Text.LabelMedium style={{ textTransform: 'capitalize' }}>
                    00/00/0000{/* TODO released at date */}
                  </Text.LabelMedium>
                </Flex.Item>
                <Flex.Item flex={2} className="td">
                  <Text.Paragraph fontWeight={600} color={Colors.primary}>
                    Release
                  </Text.Paragraph>
                </Flex.Item>
              </Flex.Container>
            ))}
          </Flex.Container>
        </Flex.Container>
        {/* {selectedPlatforms.length > 0 && (
          <Flex.Container
            justifyContent="center"
            display="flex"
            style={{ position: 'fixed', width: '80%', bottom: 20 }}
          >
            <Flex.Container
              gap="2rem"
              alignItems="center"
              padding="1rem"
              style={{
                width: '550px',
                border: `1px solid ${Colors.borderInput}`,
                backgroundColor: Colors.white,
              }}
            >
              <Flex.Item flex={1}>
                <Text.LabelSmall>
                  {`${selectedPlatforms.length} ${translate('WORKS_PAGES.STORES_SELECTED')}`}
                </Text.LabelSmall>
              </Flex.Item>
              <Flex.Item flex={2}>
                <InputTextAutocomplete
                  items={[{ value: 'Action', label: 'Action' }]}
                  defaultOption={{ value: 'Action', label: 'Action' }}
                />
              </Flex.Item>
              <Flex.Item flex={1} style={{ marginTop: '2px' }}>
                <Button $size="md" $full>
                  <Text.SectionSubtitleBold style={{ color: Colors.white }}>
                    {translate('DIGITAL_DISTRIBUTION.APPLY')}
                  </Text.SectionSubtitleBold>
                </Button>
              </Flex.Item>
            </Flex.Container>
          </Flex.Container>
        )} */}
      </Flex.Container>
      <Modal
        isOpen={editDistribution}
        onClose={() => setEditDistribution(false)}
        closeable
        position="top"
        size="lg"
        backdropCloseable
      >
        {id && (
          <Flex.Container flexDirection="column" gap="40px" padding="30px 90px 20px 90px">
            <MusicalReleaseDistributionForm
              musicalReleaseId={id}
              distributionData={digitalDistributions.map((dd) => dd.distribution)}
              closeModal={() => setEditDistribution(false)}
              saveDistributions={(distribution) => saveDistributions(distribution)}
            />
          </Flex.Container>
        )}
      </Modal>
    </Section>
  );
};

export default DigitalDistributionComponent;

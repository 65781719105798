import { useEffect, useState } from 'react';
import {
  CreateRecordingProps,
  UpdateRecordingProps,
  Recording,
  RecordingId,
  ISRC,
  AuthorComposer,
  Cradle,
  RecordingType,
  Contributor,
} from '@solo/app-core';
import { toast } from 'react-toastify';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import {
  CheckboxWithLabel,
  Icon,
  InputTextAutocomplete,
  InputWithLabel,
  Modal,
  RadioButtonWithLabel,
  TextAreaWithLabel,
} from '@/app/ui/molecules';
import {
  ArtistsTableComponent,
  AuthorsComposersTableComponent,
  AuthorsNameTableComponent,
  FileUploadComponent,
  RecordingAuthorComposerPopUp,
} from '@/app/ui/organisms';
import { useCradle } from '@/app/contexts';
import Colors from '@/app/styles/Colors';

export const languages = [
  'Spanish',
  'English',
  'French',
  'Italian',
  'German',
  'Rusian',
  'Japanese',
  'Chinese',
  'Arabic',
];
export const roles = [
  'Remixer',
  'Singer',
  'Writer',
  'Featuring',
  'With',
  'Performer',
  'Drums',
  'Translator',
  'Adaptor',
  'Actor',
];
interface RecordingFormProps {
  closeForm: () => void;
  recordingData?: Recording;
  createRecording: (recording: CreateRecordingProps) => void;
  updateRecording: (recording: UpdateRecordingProps, recordingId: RecordingId) => void;
  setNewAudio: (audio: File) => void;
}

const RecordingForm = (props: RecordingFormProps) => {
  const { closeForm, recordingData, createRecording, updateRecording, setNewAudio } = props;
  const { authBloc } = useCradle<Cradle>();
  const [recordingFormData, setRecordingFormData] = useState<
    CreateRecordingProps | UpdateRecordingProps
  >({
    ...recordingData,
    explicit: recordingData?.explicitContent || false,
    name: recordingData?.title,
    resourceId: recordingData?.audioId,
    languageId: recordingData?.languageId,
  });

  const [fieldsMissing, setFieldsMissing] = useState<boolean>(false);

  const [originalAuthors, setOriginalAuthors] = useState<string[] | undefined>();
  // const [newOriginalAuthor, setNewOriginalAuthor] = useState<string | undefined>();

  // const [newArtist, setNewArtist] = useState<Partial<RecordingArtist>>({});
  const [recordingAuthors, setRecordingAuthors] = useState<AuthorComposer[]>(
    recordingData?.authors || [],
  );
  const [addingAuthorComposer, setAddingAuthorComposer] = useState<boolean>(false);

  const [otherArtist, setOtherArtist] = useState<string>();
  const [otherArtistRole, setOtherArtistRole] = useState<number>();

  const [hasIsrc, setHasIsrc] = useState<boolean | undefined>(recordingData?.isrc !== undefined);
  const [isrc, setIsrc] = useState<ISRC | undefined>();

  const [audio, setAudio] = useState<File | undefined>();

  useEffect(() => {
    if (audio) setNewAudio(audio);
  }, [audio]);

  function deleteOriginalAuthor(author: string) {
    setOriginalAuthors(originalAuthors?.filter((currentAuthor) => currentAuthor !== author));
  }

  // function addNewOriginalAuthor() {
  //   if (originalAuthors && newOriginalAuthor) {
  //     setOriginalAuthors([...originalAuthors, newOriginalAuthor]);
  //     setNewOriginalAuthor(undefined);
  //   }
  // }

  function deleteRecordingAuthor(recordingAuthor: AuthorComposer) {
    setRecordingAuthors(
      recordingAuthors?.filter(
        (currentRecordingAuthor) => currentRecordingAuthor !== recordingAuthor,
      ),
    );
  }

  function updateRecordingAuthors(recordingAuthor: AuthorComposer) {
    const existingRecordingAuthorIndex = recordingAuthors.findIndex(
      (ra) => ra.authorComposerId === recordingAuthor.authorComposerId,
    );

    if (existingRecordingAuthorIndex === undefined) return;
    const auxRecordingAuthors = [...recordingAuthors];
    auxRecordingAuthors[existingRecordingAuthorIndex] = recordingAuthor;
    setRecordingAuthors(auxRecordingAuthors);
  }

  function deleteArtist(artist: Contributor) {
    const artistIndex = recordingFormData.contributors?.findIndex(
      (a) => a.artist.name === artist.artist.name,
    );
    if (artistIndex === undefined || !recordingFormData.contributors) return;
    let artists: Contributor[] | undefined;
    if (recordingFormData.contributors.length > 1) {
      artists = recordingFormData.contributors?.splice(artistIndex - 1, 1);
    }
    setRecordingFormData({
      ...recordingFormData,
      contributors: artists,
    });
  }

  function addOtherArtist() {
    if (!recordingFormData || !otherArtist || otherArtistRole === undefined) return;
    let otherArtists = recordingFormData.contributors;
    const newArtist = { artist: { name: otherArtist }, roleId: otherArtistRole };
    if (!otherArtists) {
      otherArtists = [newArtist];
    } else {
      otherArtists.push(newArtist);
    }
    setRecordingFormData({ ...recordingFormData, contributors: otherArtists });
    setOtherArtist(undefined);
  }

  function clearForm() {
    setRecordingFormData({});
    setRecordingAuthors([]);
    setOriginalAuthors([]);
  }

  function checkMandatory() {
    if (
      recordingFormData.name === undefined ||
      recordingFormData.explicit === undefined ||
      recordingFormData.artistName === undefined ||
      recordingAuthors.length === 0
      // (!audio && !recordingData?.audioId)
    ) {
      return false;
    }

    return true;
  }

  function saveData() {
    if (authBloc.state.kind !== 'AuthenticatedAuthState') return;
    if (!checkMandatory()) {
      setFieldsMissing(true);
      throw new Error('All mandatory fields must be filled!');
    }

    const data: CreateRecordingProps = {
      ...recordingFormData,
      authors: recordingAuthors.map((au) => ({ ...au, composerId: au.authorComposerId })),
      isrc: isrc || null,
      userId: authBloc.state.user.id,
      name: recordingFormData.name || '',
      trackType: RecordingType.ORIGINAL ? 1 : 3,
      explicit: recordingFormData.explicit || false,
      lyrics: recordingFormData.lyrics || '',
      resourceId: audio?.name || '',
      artistName: recordingFormData.artistName || '',
      tracksLocals: [],
      artistLocals: [],
      contributors: recordingFormData?.contributors || [],
      artistAppleId: '',
      artistSpotifyId: '',
      languageId: 1,
    };

    if (recordingData) {
      updateRecording(data, recordingData.id);
      return;
    }
    createRecording(data);
  }

  function saveDataAndClose() {
    try {
      saveData();
      closeForm();
    } catch (error) {
      toast.error(translate('TOAST.MANDATORY_ERROR'));
    }
  }

  async function saveDataAndContinue() {
    try {
      saveData();
      clearForm();
    } catch (error) {
      toast.error(translate('TOAST.MANDATORY_ERROR'));
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Flex.Container flexDirection="column" gap="4rem" style={{ width: '100%' }}>
      <Flex.Container gap="30px">
        <Text.SubtitleBold>
          {recordingData === undefined
            ? translate('WORKS_PAGES.NEW_TRACK')
            : translate('WORKS_PAGES.EDIT_TRACK')}
        </Text.SubtitleBold>
        <Button $transparent $variant="primary" onClick={() => closeForm()}>
          <Text.LabelMedium style={{ padding: 0 }} fontWeight={600}>
            {translate('PROFILE_INFO.CANCEL')}
          </Text.LabelMedium>
        </Button>
      </Flex.Container>
      <Flex.Container gap="30px" flexDirection="column">
        <Text.SubtitleBold>{`1. ${translate('TRACKS.TRACK_TITLE')}`}</Text.SubtitleBold>
        <InputWithLabel
          placeholder={translate('TRACKS.TRACK_TITLE')}
          label={translate('TRACKS.TRACK_TITLE')}
          value={recordingFormData?.name}
          onChange={(name) => setRecordingFormData({ ...recordingFormData, name })}
          mandatoryError={fieldsMissing && !recordingFormData.name}
        />
        {/* <InputWithLabel
          tooltip
          placeholder={translate('TRACKS.ALTERNATIVE_TITLE')}
          label={translate('TRACKS.ALTERNATIVE_TITLE')}
          value={recordingFormData?.alternativeTitle}
          onChange={(alternativeTitle) =>
            setRecordingFormData({ ...recordingFormData, alternativeTitle })
          }
        /> */}
      </Flex.Container>
      <Flex.Container gap="20px" flexDirection="column">
        <Text.SubtitleBold>{`2. ${translate('WORKS_PAGES.TRACK_DETAILS')}`}</Text.SubtitleBold>
        {/* <Flex.Container style={{ width: '49%' }}>
          <InputTextAutocomplete
            onChange={(type) =>
              setRecordingFormData({ ...recordingFormData, type: type as RecordingType })
            }
            ref={trackTypeRef}
            label={translate('TRACKS.COMPOSITION_TYPE')}
            tooltip
            items={[
              { value: RecordingType.ORIGINAL, label: translate('TRACKS.ORIGINAL') },
              { value: RecordingType.COVER, label: 'Cover' },
            ]}
            defaultOption={
              recordingFormData.type
                ? { value: recordingFormData.type, label: recordingFormData.type }
                : { value: RecordingType.ORIGINAL, label: translate('TRACKS.ORIGINAL') }
            }
          />
        </Flex.Container> */}
        {/* {recordingFormData.type === 'COVER' && (
          <Flex.Container
            style={{ backgroundColor: Colors.whitePurple }}
            flexDirection="column"
            gap="16px"
            padding="24px 32px 32px 24px"
          >
            <Flex.Container gap="0.5rem">
              <Icon.AlertCircleIcon />
              <Text.LabelMedium>{translate('WORKS_PAGES.HAVE_LICENSE')}</Text.LabelMedium>
            </Flex.Container>
            <Flex.Container flexDirection="column" gap="16px" padding="0px 0px 0px 24px">
              <RadioButtonWithLabel
                checked={hasLicense || false}
                label={translate('TRACKS.YES')}
                onChange={() => setHasLicense(true)}
              />
              <RadioButtonWithLabel
                checked={!hasLicense && hasLicense !== undefined}
                label={translate('TRACKS.NO')}
                onChange={() => setHasLicense(false)}
              />
              {!hasLicense && (
                <Text.LabelSmallBold>{translate('WORKS_PAGES.NO_LICENSE')}</Text.LabelSmallBold>
              )}
            </Flex.Container>
          </Flex.Container>
        )} */}
        <Flex.Container gap="16px">
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('WORKS.LANGUAGE')}
              tooltip
              items={Array.from(languages, (language: string) => ({
                value: language,
                label: language,
              }))}
              defaultOption={
                recordingFormData.languageId !== undefined
                  ? {
                      value: recordingFormData.languageId.toString(),
                      label: languages[recordingFormData.languageId],
                    }
                  : undefined
              }
              onChange={(language) =>
                setRecordingFormData({
                  ...recordingFormData,
                  languageId: languages.indexOf(language),
                })
              }
            />
          </Flex.Container>
          <Flex.Container flex={1}>
            <InputTextAutocomplete
              label={translate('TRACKS.EXPLICIT_CONTENT')}
              placeholder={`${translate('TRACKS.YES')}/${translate('TRACKS.NO')}`}
              tooltip
              items={Array.from(
                [translate('TRACKS.YES'), translate('TRACKS.NO')],
                (response: string) => ({
                  value: response,
                  label: response,
                }),
              )}
              defaultOption={
                recordingFormData.explicit !== undefined
                  ? {
                      value: recordingFormData.explicit
                        ? translate('TRACKS.YES')
                        : translate('TRACKS.NO'),
                      label: recordingFormData.explicit
                        ? translate('TRACKS.YES')
                        : translate('TRACKS.NO'),
                    }
                  : undefined
              }
              onChange={(explicit) =>
                setRecordingFormData({
                  ...recordingFormData,
                  explicit: explicit === translate('TRACKS.YES'),
                })
              }
              mandatoryError={fieldsMissing && recordingFormData.explicit === undefined}
            />
          </Flex.Container>
        </Flex.Container>
        <Flex.Container gap="25px" flexDirection="column">
          <Text.SubtitleBold>
            {translate('TRACKS.AUDIO')}
            <Text.LabelSmall style={{ color: Colors.grey, marginLeft: '12px' }}>
              {translate('TRACKS.AUDIO_VALID')}
            </Text.LabelSmall>
          </Text.SubtitleBold>
          <FileUploadComponent type="audio" onChange={(audioFile) => setAudio(audioFile)} />
        </Flex.Container>
        <TextAreaWithLabel
          rows={5}
          height="120px"
          placeholder={translate('TRACKS.LYRICS')}
          label={translate('TRACKS.LYRICS')}
          defaultValue={recordingFormData?.lyrics || undefined}
          onChange={(lyrics: string) => setRecordingFormData({ ...recordingFormData, lyrics })}
        />
      </Flex.Container>
      <Flex.Container gap="30px" flexDirection="column">
        <Flex.Container gap="10px" alignItems="center">
          <Text.SubtitleBold>{`3. ${translate('WORKS_PAGES.ARTIST_PERFORMER')}`}</Text.SubtitleBold>
          <Icon.InfoIcon />
        </Flex.Container>

        <Flex.Container gap="16px" flexDirection="column">
          <Flex.Container flex={4}>
            <Flex.Item flex={1}>
              <InputWithLabel
                label={translate('WORKS.MAIN_ARTIST')}
                placeholder={translate('WORKS.MAIN_ARTIST')}
                onChange={(name) =>
                  setRecordingFormData({ ...recordingFormData, artistName: name })
                }
                value={recordingFormData.artistName}
              />
            </Flex.Item>
            <Flex.Item flex={1} />
          </Flex.Container>

          <Flex.Container flex={3} gap="2rem" alignItems="flex-end">
            <Flex.Item flex={2}>
              <InputWithLabel
                placeholder={translate('WORKS.OTHER_ARTISTS')}
                label={translate('WORKS.OTHER_ARTISTS')}
                onChange={(name) => setOtherArtist(name)}
                value={otherArtist}
              />
            </Flex.Item>
            <Flex.Item flex={2}>
              <InputTextAutocomplete
                label={translate('TRACKS.ROLE')}
                placeholder={translate('WORKS_PAGES.TYPES_OF_ROLE')}
                items={Array.from(roles, (role: string) => ({
                  value: role,
                  label: role,
                }))}
                onChange={(role) => setOtherArtistRole(roles.findIndex((r) => r === role))}
              />
            </Flex.Item>
            <Flex.Item flex={1}>
              <Button
                $size="md"
                $variant="primary"
                style={{ height: '38px', marginTop: '21px' }}
                onClick={() => addOtherArtist()}
              >
                <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                  {translate('BUTTON.ADD_ARTIST')}
                </Text.SectionSubtitle>
              </Button>
            </Flex.Item>
            {/* <InputTextAutocomplete
              label={translate('TRACKS.ROLE')}
              placeholder={translate('WORKS_PAGES.TYPES_OF_ROLE')}
              items={Array.from(roles, (role: string) => ({
                value: role,
                label: role,
              }))}
              onChange={(role) => setNewArtist({ ...newArtist, roleId: parseInt(role, 10) })}
              defaultOption={
                newArtist.roleId ? { value: newArtist.roleId, label: newArtist.roleId } : undefined
              }
            /> */}
          </Flex.Container>
          <ArtistsTableComponent
            artists={recordingFormData.contributors || []}
            deleteArtist={(artist) => deleteArtist(artist)}
          />
        </Flex.Container>
      </Flex.Container>
      {/* {recordingFormData.type === RecordingType.ORIGINAL ? ( */}
      <Flex.Container gap="30px" flexDirection="column">
        <Flex.Container gap="10px" alignItems="center">
          <Text.SubtitleBold>{`4. ${translate('WORKS_PAGES.AUTHOR_COMPOSERS')}`}</Text.SubtitleBold>
          <Icon.InfoIcon />
        </Flex.Container>
        <Flex.Container alignItems="center">
          <CheckboxWithLabel label={translate('WORKS_PAGES.SONG_EDITED')} tooltip />
          <Button
            $size="md"
            $variant="primary"
            style={{ height: '40px', marginLeft: 'auto' }}
            onClick={() => setAddingAuthorComposer(true)}
          >
            <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.ADD_AUTHOR_COMPOSER')}
            </Text.SectionSubtitle>
          </Button>
        </Flex.Container>
        {recordingAuthors && recordingAuthors?.length > 0 ? (
          <AuthorsComposersTableComponent
            recordingAuthors={recordingAuthors}
            deleteRecordingAuthor={(recordingAuthor) => deleteRecordingAuthor(recordingAuthor)}
            saveRecordingAuthor={(recordingAuthor) => updateRecordingAuthors(recordingAuthor)}
          />
        ) : (
          <Text.ErrorMessage>
            {fieldsMissing && translate('TRACKS.MISSING_AUTHOR')}
          </Text.ErrorMessage>
        )}
      </Flex.Container>
      {/* ) : ( */}
      {/* <Flex.Container flexDirection="column" gap="32px">
          <Flex.Container gap="10px" alignItems="center">
            <Text.SubtitleBold>{`4. ${translate(
              'TRACKS.ORIGINAL_SONG_DETAILS',
            )}`}</Text.SubtitleBold>
            <Icon.InfoIcon />
          </Flex.Container>
          <Flex.Container style={{ width: '49%' }}>
            <InputWithLabel
              placeholder={translate('TRACKS.ORIGINAL_TITLE')}
              label={translate('TRACKS.ORIGINAL_TITLE')}
              defaultValue={recordingData?.originalSongTitle}
              onChange={(originalSongTitle) =>
                setRecordingFormData({ ...recordingFormData, originalSongTitle })
              }
            />
          </Flex.Container>
          <Flex.Container gap="16px" style={{ width: '100%' }}>
            <Flex.Container flex={1}>
              <InputWithLabel
                placeholder={translate('WORKS_PAGES.AUTHOR_COMPOSER')}
                label={translate('WORKS_PAGES.AUTHOR_COMPOSER')}
                onChange={(name) => setNewOriginalAuthor(name)}
                value={newOriginalAuthor}
              />
            </Flex.Container>
            <Flex.Container flex={1}>
              <Button
                $size="md"
                $variant="primary"
                style={{ marginTop: '20px', height: '40px', width: '170px' }}
                onClick={() => addNewOriginalAuthor()}
              >
                <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                  {translate('BUTTON.ADD')}
                </Text.SectionSubtitle>
              </Button>
            </Flex.Container>
          </Flex.Container>
        </Flex.Container> */}
      {/* )} */}
      {originalAuthors !== undefined && originalAuthors?.length > 0 && (
        <AuthorsNameTableComponent
          authors={originalAuthors}
          deleteAuthor={(author: string) => deleteOriginalAuthor(author)}
        />
      )}
      <Flex.Container gap="30px" flexDirection="column">
        <Text.SubtitleBold>5. ISRC</Text.SubtitleBold>
        <Flex.Container gap="20px" flexDirection="column">
          <RadioButtonWithLabel
            checked={!hasIsrc && hasIsrc !== undefined}
            label={translate('WORKS_PAGES.NEED_ISRC')}
            onChange={() => setHasIsrc(false)}
          />
          <RadioButtonWithLabel
            checked={hasIsrc || false}
            label={translate('WORKS_PAGES.HAVE_ISRC')}
            onChange={() => setHasIsrc(true)}
          />
          {hasIsrc && (
            <InputWithLabel
              placeholder="ISRC"
              label="ISRC"
              value={recordingData?.isrc}
              onChange={(i) => setIsrc(i)}
            />
          )}
        </Flex.Container>
      </Flex.Container>
      <Flex.Container style={{ marginLeft: 'auto' }} gap="30px">
        <Button
          $transparent
          $variant="primary"
          onClick={() => {
            closeForm();
          }}
        >
          <Text.SectionSubtitle>{translate('PROFILE_INFO.CANCEL')}</Text.SectionSubtitle>
        </Button>
        <Button
          $size="sm"
          $variant="reversed"
          $transparent
          $outline
          style={{ height: '40px', width: '160px' }}
          onClick={() => {
            saveDataAndClose();
          }}
        >
          <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
            {translate('PROFILE_INFO.SAVE')}
          </Text.SectionSubtitle>
        </Button>
        {!recordingData && (
          <Button
            $size="md"
            $variant="primary"
            style={{ width: '290px' }}
            onClick={() => {
              saveDataAndContinue();
            }}
          >
            <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
              {translate('BUTTON.SAVE_ADD_TRACK')}
            </Text.SectionSubtitle>
          </Button>
        )}
      </Flex.Container>
      <Modal
        isOpen={addingAuthorComposer}
        onClose={() => setAddingAuthorComposer(false)}
        closeable
        position="top"
        size="md"
        backdropCloseable
      >
        <RecordingAuthorComposerPopUp
          currentRecordingAuthors={recordingAuthors}
          closeModal={() => setAddingAuthorComposer(false)}
          saveRecordingAuthor={(recordingAuthor: AuthorComposer) =>
            setRecordingAuthors([...recordingAuthors, recordingAuthor])
          }
        />
      </Modal>
    </Flex.Container>
  );
};

export default RecordingForm;

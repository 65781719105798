/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import {
  Cradle,
  DigitalPlatform,
  DigitalPlatformId,
  PaginatedEntity,
  Recording,
  RecordingsWithPlays,
} from '@solo/app-core';
import { toast } from 'react-toastify';
import { Flex, InputTextAutocomplete, QuantityPerItemList } from '@/app/ui';
import { useCradle } from '@/app/contexts';
import { getDateFrom, spanFilters, TimeSpan } from '@/app/utils/GetDateFrom';
import { QuantityPerItemObject } from '@/app/ui/organisms/QuantityPerItemList/QuantityPerItemList';
import { useBlocState } from '@/app/hooks';
import { stringToInt } from '@/app/utils/StringToInt';

const PlaysBySongAndPlatform = () => {
  const [currentFromDate, setCurrentFromDate] = useState<Date>(getDateFrom(TimeSpan.lastYear));
  const [topPlatforms, setTopPlatforms] = useState<QuantityPerItemObject[]>([]);

  const [platformFilter, setPlatformFilter] = useState<DigitalPlatformId | undefined>();

  const { consumptionReportsBloc, digitalDistributionBloc, musicalWorksBloc, authBloc } =
    useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const authState = useBlocState(authBloc);

  const digitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  useEffect(() => {
    async function getTopSongsData() {
      let recordings: PaginatedEntity<Recording> | undefined;
      if (authState.kind !== 'AuthenticatedAuthState') return;
      try {
        recordings = await musicalWorksBloc.getRecordings({
          filters: { userId: authState.user.id },
        });
      } catch (error) {
        toast.error('Something went wrong when loading recordings.');
        return;
      }

      const recordingIds = recordings.data.map((rec) => rec.id);
      let topSongs: RecordingsWithPlays[] = [];
      try {
        topSongs = await consumptionReportsBloc.getPlaysByRecordings({
          props: {
            topRecordings: 10,
            filters: {
              dateFrom: currentFromDate || undefined,
              digitalPlatformId: platformFilter,
              recordingIds,
            },
          },
        });
      } catch (error) {
        toast.error('Something went wrong when loading plays.');
        return;
      }

      const topSongsData: QuantityPerItemObject[] = [];
      try {
        await Promise.all(
          topSongs.map(async (song) => {
            const currentSong = await musicalWorksBloc.getRecording(song.recordingId);
            topSongsData.push({
              name: currentSong.title,
              quantity: topSongs.find((s) => s.recordingId === song.recordingId)?.plays || 0,
            });
          }),
        );
      } catch {
        toast.error('Something went wrong when loading recordings.');
        return;
      }
      setTopPlatforms(topSongsData);
    }
    getTopSongsData();
  }, [currentFromDate, platformFilter]);

  return (
    <Flex.Container className="mt-5" flexDirection="column" gap="4rem" style={{ width: '100%' }}>
      <Flex.Container style={{ width: '80%' }} gap="1rem">
        <InputTextAutocomplete
          placeholder="Platform..."
          items={[{ value: 'All', label: 'All' }].concat(
            digitalPlatforms.map((p) => ({ value: p.id.toString(), label: p.name })),
          )}
          onChange={(groupFilter) =>
            groupFilter === 'All'
              ? setPlatformFilter(undefined)
              : setPlatformFilter(stringToInt(groupFilter))
          }
        />
        <InputTextAutocomplete
          items={spanFilters}
          onChange={(filter) => setCurrentFromDate(getDateFrom(filter as TimeSpan))}
          isSearchable={false}
        />
      </Flex.Container>
      <Flex.Container gap="1rem 3rem" style={{ width: '100%' }}>
        <QuantityPerItemList
          items={topPlatforms}
          flexDirection="column"
          platform={digitalPlatforms.find((platform) => platformFilter === platform.id)}
        />
      </Flex.Container>
    </Flex.Container>
  );
};

export default PlaysBySongAndPlatform;

import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Cradle } from '@solo/app-core';
import { Flex, Text, Container, Section, NavBarProfile, Modal, RouterLink } from '@/app/ui';
import Colors from '@/app/styles/Colors';
import { translate } from '@/app/i18n';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

const ProfilePage = () => {
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const [showInfoMissingModal, setShowInfoMissingModal] = useState<boolean>(false);

  useEffect(() => {
    if (authState.kind !== 'AuthenticatedAuthState') return;

    if (!authState.user.isUserValidated()) {
      setShowInfoMissingModal(true);
    }
  }, []);
  return (
    <>
      <Section py={15}>
        <Container>
          <Text.PageTitle>{translate('PAGE_TITLE.PROFILE')}</Text.PageTitle>
        </Container>
      </Section>
      <Section py={15} backgroundColor={Colors.white}>
        <Container>
          <NavBarProfile />
        </Container>
      </Section>
      <Section>
        <Container>
          <Flex.Container>
            <Flex.Item>
              <Outlet />
            </Flex.Item>
          </Flex.Container>
        </Container>
        <Modal isOpen={showInfoMissingModal} position="center" size="xs" backdropCloseable>
          <Flex.Container flexDirection="column" alignItems="center" gap="1.5rem">
            <Text.Span>{translate('SIGN_UP.PLEASE_COMPLETE_SIGNUP')}</Text.Span>
            <RouterLink to="/auth/sign-up/" $size="md">
              <Text.SectionSubtitleBold style={{ color: Colors.white }}>
                {translate('SIGN_UP.COMPLETE_SIGNUP')}
              </Text.SectionSubtitleBold>
            </RouterLink>
          </Flex.Container>
        </Modal>
      </Section>
    </>
  );
};

export default ProfilePage;

import { useEffect, useState } from 'react';
import {
  Cradle,
  CreateRecordingProps,
  Recording,
  RecordingId,
  UpdateRecordingProps,
} from '@solo/app-core';
import { translate } from '@/app/i18n';
import { Button, Flex, Text } from '@/app/ui/atoms';
import { InputTextAutocomplete } from '@/app/ui/molecules';
import { RecordingTableComponent, RecordingForm } from '@/app/ui/organisms';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

interface MusicalReleaseRecordingsFormProps {
  recordings: Recording[];
  // type: string;
  showNavButtons?: (show: boolean) => void;
  addRecording: (recording: Recording) => void;
  removeRecording: (recordingId: RecordingId) => void;
  createRecording: (recording: CreateRecordingProps) => void;
  updateRecording: (recording: UpdateRecordingProps, recordingId: RecordingId) => void;
  reorderRecordings: (recordingId: string, indexTo: number) => void;
  setNewAudio: (audio: File) => void;
  closeForm?: () => void;
}

const MusicalReleaseRecordingsForm = (props: MusicalReleaseRecordingsFormProps) => {
  const {
    recordings,
    // type,
    showNavButtons,
    addRecording,
    removeRecording,
    createRecording,
    updateRecording,
    reorderRecordings,
    closeForm,
    setNewAudio,
  } = props;
  const [myRecordings, setMyRecordings] = useState<Recording[]>([]);
  const [addingNewTrack, setAddingNewTrack] = useState<boolean>(false);
  const [selectedRecordingId, setSelectedRecordingId] = useState<string | undefined>();
  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);
  const { authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);
  const [notEditableRecordingsIds, setNotEditableRecordingsIds] = useState<RecordingId[]>([]);

  useEffect(() => {
    async function getMyRecordings() {
      const rec = await musicalWorksBloc.getRecordings({
        filters: {
          userId: authState.kind === 'AuthenticatedAuthState' ? authState.user.id : undefined,
        },
      });
      setMyRecordings(rec.data);
    }
    getMyRecordings();
  }, [musicalWorksState, recordings]);

  const title = translate('WORKS_PAGES.ADD_RELEASE_TRACKS');
  // switch (type) {
  //   case 'ALBUM':
  //     title = translate('WORKS_PAGES.ADD_ALBUM_TRACKS');
  //     break;
  //   case 'SINGLE':
  //     title = translate('WORKS_PAGES.ADD_SINGLE_TRACKS');
  //     break;
  //   default:
  //     title = translate('WORKS_PAGES.ADD_EP_TRACKS');
  // }

  function addTrack() {
    const t = myRecordings.find((track) => track.id === selectedRecordingId);
    if (t && !recordings.includes(t)) {
      addRecording(t);
      setNotEditableRecordingsIds([...notEditableRecordingsIds, t.id]);
    }
    setSelectedRecordingId(undefined);
  }

  function deleteTrack(track: Recording) {
    removeRecording(track.id);
    setNotEditableRecordingsIds(
      notEditableRecordingsIds.filter((recordingId) => recordingId !== track.id),
    );
  }

  // function orderTracks(recordingId: string, indexTo: number) {
  //   // const newOrderRecordings = displayedRecordings.map((r) => r);
  //   // const currentRecording = newOrderRecordings.splice(recordingId, 1);
  //   // newOrderRecordings.splice(indexTo, 0, currentRecording[0]);
  //   reorderRecordings(recordingId, indexTo);
  // }

  return (
    <Flex.Container flexDirection="column" gap="4rem" style={{ width: '100%' }}>
      {addingNewTrack ? (
        <RecordingForm
          createRecording={(recording) => createRecording(recording)}
          updateRecording={(recording, recordingId) => updateRecording(recording, recordingId)}
          closeForm={() => {
            setAddingNewTrack(false);
            if (showNavButtons) showNavButtons(true);
          }}
          setNewAudio={(audioFile) => setNewAudio(audioFile)}
        />
      ) : (
        <Flex.Container flexDirection="column" gap="25px">
          <Flex.Container gap="25px" flexDirection="column">
            <Text.SubtitleBold>{title}</Text.SubtitleBold>
            <Text.LabelMedium>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. At nec cras mauris, blandit
              in id non tellus. Tincidunt quis facilisi fermentum at eu.us
            </Text.LabelMedium>
          </Flex.Container>
          <Flex.Container gap="16px" style={{ width: '100%' }} alignItems="flex-end">
            <Flex.Container flex={4}>
              <InputTextAutocomplete
                label={translate('WORKS_PAGES.ADD_EXISTING_TRACK')}
                placeholder={`${translate('WORKS.SEARCH_BY')}...`}
                items={Array.from(myRecordings, (track) => ({
                  value: track.id,
                  label: track.title,
                }))}
                onChange={(id) => setSelectedRecordingId(id)}
                defaultOption={
                  selectedRecordingId !== undefined
                    ? {
                        value: selectedRecordingId,
                        label:
                          myRecordings.find((rec) => rec.id === selectedRecordingId)?.title || '',
                      }
                    : undefined
                }
              />
            </Flex.Container>
            <Flex.Container flex={1}>
              <Button
                $size="md"
                $variant="primary"
                style={{ height: '38px', width: '120px' }}
                onClick={() => {
                  addTrack();
                }}
              >
                <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                  {translate('BUTTON.ADD')}
                </Text.SectionSubtitle>
              </Button>
            </Flex.Container>
            <Flex.Container flex={2} padding="38px 0px 0px 0px" justifyContent="center">
              <Text.LabelMedium>Or</Text.LabelMedium>
            </Flex.Container>
            <Flex.Container flex={2}>
              <Button
                $size="sm"
                $variant="reversed"
                $transparent
                $outline
                style={{ height: '38px', width: '160px' }}
                onClick={() => {
                  setAddingNewTrack(true);
                  // eslint-disable-next-line no-console
                  if (showNavButtons !== undefined) showNavButtons(false);
                }}
              >
                <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                  {translate('BUTTON.ADD_NEW_TRACK')}
                </Text.SectionSubtitle>
              </Button>
            </Flex.Container>
          </Flex.Container>
          {recordings && recordings?.length > 0 && (
            <RecordingTableComponent
              key={recordings.map((r) => r.id).toString()}
              changeOrder={(recordingId, indexTo) => reorderRecordings(recordingId, indexTo)}
              createRecording={(recording) => createRecording(recording)}
              updateRecording={(recording, recordingId) => updateRecording(recording, recordingId)}
              recordings={recordings}
              notEditableRecordingsIds={notEditableRecordingsIds}
              deleteRecording={(track: Recording) => deleteTrack(track)}
              setNewAudio={(audio) => setNewAudio(audio)}
            />
          )}
          {closeForm && (
            <Flex.Container style={{ marginLeft: 'auto' }} gap="20px">
              <Button
                $transparent
                $variant="primary"
                onClick={() => {
                  closeForm();
                }}
              >
                <Text.SectionSubtitle>{translate('PROFILE_INFO.CANCEL')}</Text.SectionSubtitle>
              </Button>

              <Button
                $size="md"
                $variant="primary"
                style={{ width: '290px' }}
                onClick={() => {
                  closeForm();
                }}
              >
                <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                  {translate('PROFILE_INFO.SAVE')}
                </Text.SectionSubtitle>
              </Button>
            </Flex.Container>
          )}
        </Flex.Container>
      )}
    </Flex.Container>
  );
};

export default MusicalReleaseRecordingsForm;

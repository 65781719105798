import { translate } from '@/app/i18n';
import { RouterLink, Text } from '@/app/ui/atoms';
import { RecordingWithEarnings } from '@/app/ui/organisms/Royalties/TopEarningWorks';
import { Table } from '@/app/ui/molecules';

interface TopEarningWorksListProps {
  earningsData: RecordingWithEarnings[];
}

const EarningWorksList = ({ earningsData }: TopEarningWorksListProps) => (
  <Table.Container flexDirection="column">
    <Table.Thead gap="16px">
      <Table.Th flex={4} padding="0 0 0 1rem">
        <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
          {translate('WORKS.TITLE')}
        </Text.Paragraph>
      </Table.Th>
      <Table.Th flex={4}>
        <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
          {translate('WORKS.ARTIST')}
        </Text.Paragraph>
      </Table.Th>
      <Table.Th flex={2}>
        <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
          {translate('EARNINGS.PUBLISHING')}
        </Text.Paragraph>
      </Table.Th>
      <Table.Th flex={2}>
        <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
          {translate('EARNINGS.DIGITAL_DISTRIBUTION')}
        </Text.Paragraph>
      </Table.Th>
      <Table.Th flex={1}>
        <Text.Paragraph fontWeight={600} style={{ padding: 0 }}>
          {translate('EARNINGS.TOTAL')}
        </Text.Paragraph>
      </Table.Th>
    </Table.Thead>
    <Table.Tbody flexDirection="column">
      {earningsData.map((earningData) => (
        <Table.Tr key={earningData.earnings.recordingId} alignItems="center" gap="16px">
          <Table.Td flex={4} padding="0 0 0 1rem">
            <RouterLink $transparent $variant="primary" to="/">
              <Text.Paragraph fontWeight={600}>{earningData.recording.title}</Text.Paragraph>
            </RouterLink>
          </Table.Td>
          <Table.Td flex={4}>{earningData.recording.artistName}</Table.Td>
          <Table.Td flex={2}>{earningData.earnings.publishing}€</Table.Td>
          <Table.Td flex={2}>{earningData.earnings.distribution}€</Table.Td>
          <Table.Td flex={1}>{earningData.earnings.total}€</Table.Td>
        </Table.Tr>
      ))}
    </Table.Tbody>
  </Table.Container>
);

export default EarningWorksList;

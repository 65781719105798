import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryStack,
  VictoryTheme,
} from 'victory';
import Colors from '@/app/styles/Colors';
import LegendChartSize from '@/app/constants/LegendChartSize';

export interface BarData {
  x: string;
  y: number;
}

interface CustomBarChartProps {
  data: BarData[][];
  barColors: string[];
  xaxis?: string;
  yaxis?: string;
  legend?: string[];
}

const CustomBarChart = (props: CustomBarChartProps) => {
  const { data, barColors, xaxis, yaxis, legend } = props;

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      domainPadding={100}
      padding={{ top: 90, bottom: 32, left: 70, right: 70 }}
      height={340}
      width={1500}
    >
      {legend && (
        <VictoryLegend
          x={0}
          y={0}
          centerTitle
          orientation="horizontal"
          gutter={20}
          style={{ labels: { fontSize: LegendChartSize.textSize } }}
          data={legend?.map((title, index) => ({
            name: title,
            symbol: { fill: barColors[index % barColors.length] },
          }))}
        />
      )}
      <VictoryAxis
        dependentAxis
        y={40}
        style={{
          tickLabels: { fontSize: '14px', fill: Colors.black },
          grid: { stroke: Colors.borderInput, strokeDasharray: '0' },
          axis: { stroke: Colors.borderInput },
        }}
      />
      <VictoryAxis
        style={{
          tickLabels: { fontSize: '14px', fill: Colors.black },
          grid: { stroke: 'transparent' },
          axis: { stroke: Colors.borderInput },
        }}
        label={xaxis}
      />

      <VictoryLabel x={20} y={60} text={yaxis} style={{ fontSize: '18px' }} />
      <VictoryStack colorScale={barColors}>
        {data.map((d, index) => (
          <VictoryBar
            key={`${d[index]?.x} ${d[index]?.y}`}
            cornerRadius={index === data.length - 1 ? { topLeft: 5, topRight: 5 } : {}}
            data={d}
            categories={{ x: d.map((group) => group.x.toString()) }}
          />
        ))}
      </VictoryStack>
    </VictoryChart>
  );
};

export default CustomBarChart;

import { useEffect, useState } from 'react';
import {
  // Artwork,
  // Audio,
  Cradle,
  CreateMusicalReleaseProps,
  CreateRecordingProps,
  MusicalRelease,
  // MusicalReleaseId,
  Recording,
  RecordingId,
  UpdateMusicalReleaseProps,
  UpdateRecordingProps,
} from '@solo/app-core';
import { toast } from 'react-toastify';

import { Button, Flex, MusicalReleaseSummaryForm, RouterLink, Section, Text } from '@/app/ui';
import { translate } from '@/app/i18n';
import Colors from '@/app/styles/Colors';
import { FormProgress } from '@/app/ui/molecules';
import { MusicalReleaseDetailsForm, MusicalReleaseTracksForm } from '@/app/ui/organisms';
import { useCradle } from '@/app/contexts';
import { useBlocState } from '@/app/hooks';

// type MusicalReleaseFormParams = {
//   songType: string;
// };

const MusicalReleaseForm = () => {
  // const { songType } = useParams<MusicalReleaseFormParams>();
  // const [type, setType] = useState<MusicalReleaseType | undefined>();
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [musicalRelease, setMusicalRelease] = useState<MusicalRelease>();
  const addReleaseSteps = [
    translate('WORKS_PAGES.RELEASE_DETAILS'),
    translate('WORKS_PAGES.TRACKS'),
    // translate('WORKS_PAGES.DIGITAL_DISTRIBUTION'),
    translate('WORKS.SUMMARY'),
  ];
  const [showNavButtons, setShowNavButtons] = useState<boolean>(true);

  const { musicalWorksBloc } = useCradle<Cradle>();
  const musicalWorksState = useBlocState(musicalWorksBloc);
  // const { digitalDistributionBloc } = useCradle<Cradle>();
  // const [digitalDistributions, setDigitalDistributions] = useState<Distribution[]>([]);
  const [recordings, setRecordings] = useState<Recording[]>([]);
  const [artworkFile, setArtworkFile] = useState<File | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [audioFile, setAudioFile] = useState<File | undefined>();

  const [musicalReleaseDetails, setMusicalReleaseDetails] = useState<
    CreateMusicalReleaseProps | UpdateMusicalReleaseProps
  >();
  const [detailsFieldsMissing, setDetailsFieldsMissing] = useState<boolean>();

  // const [musicalReleaseDistribution, setMusicalReleaseDistribution] =
  //   useState<MusicalReleaseDistributionData>();

  useEffect(() => {
    async function getMusicalRelease() {
      if (!musicalRelease) return;
      const musicalRel = await musicalWorksBloc.getMusicalRelease(musicalRelease.id);
      setMusicalRelease(musicalRel);

      const recs: Recording[] = [];

      musicalRel?.recordings?.forEach(async (recording) => {
        const rec = await musicalWorksBloc.getRecording(recording.id);
        recs.push(rec);
      });
      setRecordings(recs);
    }
    // async function getDistributions() {
    //   const distributions = await digitalDistributionBloc.getDistributions({
    //     musicalReleaseId: musicalRelease?.releaseId as MusicalReleaseId,
    //   });
    //   setDigitalDistributions(distributions);
    // }

    if (musicalRelease) getMusicalRelease();
    // getDistributions();
  }, [musicalWorksState]);

  // useEffect(() => {
  //   let [firstStep] = addReleaseSteps;
  //   const [, ...restOfSteps] = addReleaseSteps;
  //   switch (type) {
  //     case 'EP':
  //       firstStep = translate('WORKS.EP_DETAILS');
  //       break;
  //     case 'SINGLE':
  //       firstStep = translate('WORKS.SINGLE_DETAILS');
  //       break;
  //     case 'ALBUM':
  //       firstStep = translate('WORKS.ALBUM_DETAILS');
  //       break;
  //     default:
  //   }
  //   setAddReleaseSteps([firstStep, ...restOfSteps]);
  // }, [type]);

  // useEffect(() => {
  //   if (!songType) return;
  //   setType(songType.toUpperCase() as MusicalReleaseType);
  //   setCurrentStep(1);
  // }, [songType]);

  function checkMandatoryDetailsFields() {
    if (!musicalReleaseDetails) return false;
    return (
      musicalReleaseDetails.name !== '' &&
      musicalReleaseDetails.releaseDate &&
      musicalReleaseDetails.releaseDate !== '' &&
      musicalReleaseDetails.languageId !== undefined &&
      musicalReleaseDetails.primaryMusicStyleId !== undefined
    );
  }

  async function createMusicalRelease() {
    if (!musicalReleaseDetails || !checkMandatoryDetailsFields())
      throw new Error('All mandatory fields must be filled!');
    let musicalReleaseResponse: MusicalRelease | undefined;
    if (musicalRelease) {
      try {
        await musicalWorksBloc.updateMusicalRelease(musicalRelease.id, musicalReleaseDetails);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
      if (!musicalReleaseResponse || !artworkFile) return;
      try {
        await musicalWorksBloc.uploadArtwork(artworkFile);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
      musicalWorksBloc.loadMusicalReleases();
    } else {
      let resourceId: string | undefined;
      if (artworkFile) {
        try {
          resourceId = await musicalWorksBloc.uploadArtwork(artworkFile);
          setMusicalRelease(musicalReleaseResponse);
        } catch (error) {
          toast.error(translate('TOAST.CHANGES_ERROR'));
        }
      }
      try {
        musicalReleaseResponse = await musicalWorksBloc.createMusicalRelease({
          ...musicalReleaseDetails,
          resourceId,
          artistLocals: [],
          releasesLocals: [],
          artistAppleId: 'artistAppleId',
          artistSpotifyId: 'artistSpotifyId',
        } as CreateMusicalReleaseProps);
        setMusicalRelease(musicalReleaseResponse);
      } catch (error) {
        toast.error(translate('TOAST.CHANGES_ERROR'));
      }
    }
  }

  async function addRecording(recording: Recording) {
    if (!musicalRelease) return;
    try {
      await musicalWorksBloc.addRecordingsToMusicalRelease(musicalRelease.id, [recording]);
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }
    await musicalWorksBloc.loadRecordings();
  }

  async function removeRecording(recordingId: RecordingId) {
    if (musicalRelease)
      musicalWorksBloc.removeRecordingsFromMusicalRelease(musicalRelease.id, [recordingId]);
    musicalWorksBloc.loadRecordings();
  }

  async function createRecording(recording: CreateRecordingProps) {
    let recordingResponse: Recording | undefined;
    if (!musicalRelease) return;
    // if (!musicalRelease || !audioFile) return;
    // let resourceId: FileId | undefined;
    // try {
    //   resourceId = await musicalWorksBloc.uploadAudio(audioFile);
    // } catch (error) {
    //   toast.error(translate('TOAST.CHANGES_ERROR'));
    // }

    try {
      recordingResponse = await musicalWorksBloc.createRecording(
        {
          ...recording,
          resourceId: '',
          artistAppleId: 'artistAppleId',
          artistSpotifyId: 'artistSpotifyId',
        } as CreateRecordingProps,
        musicalRelease.id,
      );
    } catch (error) {
      toast.error(translate('TOAST.CHANGES_ERROR'));
    }

    // if (!recordingResponse || !audioFile) return;
    if (!recordingResponse) return;

    musicalWorksBloc.loadRecordings();
  }

  async function updateRecording(recording: UpdateRecordingProps, recordingId: RecordingId) {
    musicalWorksBloc.updateRecording(recordingId, recording);

    musicalWorksBloc.loadRecordings();
  }

  async function reorderRecordings(recordingId: string, indexTo: number) {
    if (!musicalRelease) return;
    musicalWorksBloc.reorderReleaseRecordings(musicalRelease.id, recordingId, indexTo);
    musicalWorksBloc.loadRecordings();
  }

  // async function saveDistributions() {
  //   if (!musicalReleaseDistribution) return;
  //   musicalReleaseDistribution.newDistributions.forEach(async (distribution) => {
  //     await digitalDistributionBloc.createDistribution(
  //       musicalRelease?.releaseId as MusicalReleaseId,
  //       distribution,
  //     );
  //   });
  //   musicalReleaseDistribution.updatedDistributions.forEach(async (distribution) => {
  //     await digitalDistributionBloc.updateDistribution(
  //       distribution.distributionId,
  //       distribution.props,
  //     );
  //   });
  //   musicalReleaseDistribution.deletedDistributions.forEach(async (id) => {
  //     await digitalDistributionBloc.deleteDistribution(id);
  //   });

  //   musicalWorksBloc.loadMusicalReleases();
  // }

  function endMusicalReleaseForm() {
    setMusicalRelease(undefined);
    setRecordings([]);
    // setDigitalDistributions([]);
    setCurrentStep(0);
    toast.success(translate('WORKS.RELEASE_SUCCESS'));
  }

  async function handleNextStep(step: number) {
    switch (step) {
      case 0:
        setMusicalRelease(undefined);
        break;
      case 1:
        try {
          await createMusicalRelease();
          setCurrentStep(step + 1);
        } catch (error) {
          toast.error(translate('TOAST.CHANGES_ERROR'));
          setDetailsFieldsMissing(true);
        }
        break;
      case 2:
        setCurrentStep(step + 1);
        break;
      case 3:
        try {
          // await saveDistributions();
          setCurrentStep(step + 1);
        } catch (error) {
          if (error instanceof Error) toast.error(error.message);
        }
        setCurrentStep(step + 1);
        break;
      default:
        break;
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  return (
    <Section>
      {/* {type === undefined ? (
        <MusicalReleaseTypeForm />
      ) : ( */}
      <Flex.Container
        flexDirection="column"
        style={{ width: '100%' }}
        padding="0px 140px 40px 140px"
      >
        <FormProgress
          steps={addReleaseSteps}
          currentStep={currentStep}
          canGoBack
          setCurrentStep={(step: number) => setCurrentStep(step)}
        />

        <Flex.Container
          flexDirection="column"
          gap="40px"
          padding="30px 90px 20px 90px"
          style={{ border: `1px solid ${Colors.borderInput}`, width: '100%' }}
        >
          {
            {
              1: (
                <MusicalReleaseDetailsForm
                  onChange={(details) => setMusicalReleaseDetails(details)}
                  musicalRelease={musicalRelease}
                  detailsFieldsMissing={detailsFieldsMissing}
                  setNewArtwork={(artwork) => setArtworkFile(artwork)}
                />
              ),
              2: (
                <MusicalReleaseTracksForm
                  addRecording={(recording) => addRecording(recording)}
                  removeRecording={(recordingId) => removeRecording(recordingId)}
                  createRecording={(recording) => createRecording(recording)}
                  updateRecording={(recording, recordingId) =>
                    updateRecording(recording, recordingId)
                  }
                  reorderRecordings={(recordingId, indexTo) =>
                    reorderRecordings(recordingId, indexTo)
                  }
                  recordings={recordings}
                  showNavButtons={(hide: boolean) => setShowNavButtons(hide)}
                  setNewAudio={(audio) => setAudioFile(audio)}
                />
              ),
              // 3: (
              //   <MusicalReleaseDistributionForm
              //     musicalReleaseId={musicalRelease?.releaseId as MusicalReleaseId}
              //     distributionData={digitalDistributions}
              //     onChange={(distributionData) => setMusicalReleaseDistribution(distributionData)}
              //   />
              // ),
              3: (
                <MusicalReleaseSummaryForm
                  musicalRelease={musicalRelease as MusicalRelease}
                  // digitalDistributions={digitalDistributions}
                  recordings={recordings}
                  stepBackTo={(step) => setCurrentStep(step)}
                />
              ),
            }[currentStep]
          }
          {showNavButtons && (
            <Flex.Container style={{ marginLeft: 'auto' }} gap="20px">
              <Button
                $transparent
                $variant="primary"
                onClick={() => {
                  setCurrentStep(currentStep - 1);
                }}
              >
                <Text.SectionSubtitle>
                  {currentStep > 1
                    ? translate('WORKS_PAGES.BACK')
                    : translate('PROFILE_INFO.CANCEL')}
                </Text.SectionSubtitle>
              </Button>
              {currentStep < 3 ? (
                <Button
                  $size="md"
                  $variant="primary"
                  style={{ width: '290px' }}
                  onClick={() => handleNextStep(currentStep)}
                >
                  <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                    {translate('PAGINATION.NEXT_STEP')}
                  </Text.SectionSubtitle>
                </Button>
              ) : (
                <RouterLink
                  to="/works"
                  $size="md"
                  $variant="primary"
                  style={{ width: '290px' }}
                  onClick={() => {
                    endMusicalReleaseForm();
                  }}
                >
                  <Text.SectionSubtitle style={{ padding: 0 }} fontWeight={600}>
                    {translate('PROFILE_INFO.SAVE')}
                  </Text.SectionSubtitle>
                </RouterLink>
              )}
            </Flex.Container>
          )}
        </Flex.Container>
      </Flex.Container>
    </Section>
  );
};

export default MusicalReleaseForm;

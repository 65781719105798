import { useEffect, useState } from 'react';
import { Cradle, MusicalRight } from '@solo/app-core';
import { Flex } from '@/app/ui/atoms';
import { CustomBarChart } from '@/app/ui';
import Colors from '@/app/styles/Colors';
import { useCradle } from '@/app/contexts';
import { BarData } from '@/app/ui/organisms/CustomBarChart/CustomBarChart';
import { useBlocState } from '@/app/hooks';

export type EarningData = {
  [key: string]: number;
};

const AnualEarning = () => {
  const [earningData, setEarningData] = useState<BarData[][]>([]);

  const { consumptionReportsBloc, authBloc } = useCradle<Cradle>();
  const authState = useBlocState(authBloc);

  useEffect(() => {
    async function getEarning() {
      if (authState.kind !== 'AuthenticatedAuthState') return;
      const earn = await consumptionReportsBloc.getEarnings({ userId: authState.user.id });

      const earningsDist: EarningData = {};
      const earningsPub: EarningData = {};

      earn.forEach((earning) => {
        if (earning.rightType === MusicalRight.DISTRIBUTION) {
          if (earningsDist[earning.date.getFullYear()] === undefined) {
            earningsDist[earning.date.getFullYear()] = earning.amount;
            return;
          }
          earningsDist[earning.date.getFullYear()] += earning.amount;
          return;
        }
        if (earningsPub[earning.date.getFullYear()] === undefined) {
          earningsPub[earning.date.getFullYear()] = earning.amount;
          return;
        }
        earningsPub[earning.date.getFullYear()] += earning.amount;
      });

      const earningsDistBarData: BarData[] = [];
      Object.keys(earningsDist).forEach((year: string) => {
        earningsDistBarData.push({
          x: year,
          y: earningsDist[year],
        });
      });

      const earningsPubBarData: BarData[] = [];
      Object.keys(earningsPub).forEach((year: string) => {
        earningsPubBarData.push({
          x: year,
          y: earningsPub[year],
        });
      });

      setEarningData([earningsDistBarData, earningsPubBarData]);
    }
    getEarning();
  }, []);

  return (
    <Flex.Container flexDirection="column" className="mt-1" gap="2.5rem">
      <CustomBarChart
        yaxis="Royalties"
        data={earningData}
        barColors={[Colors.primary, Colors.pink]}
        legend={['Distribution', 'Publishing']}
      />
    </Flex.Container>
  );
};

export default AnualEarning;

import { useEffect, useState } from 'react';
import { Cradle, DigitalPlatformId } from '@solo/app-core';
import { Flex } from '@/app/ui/atoms';
import { useCradle } from '@/app/contexts';
import { BarData } from '@/app/ui/organisms/CustomBarChart/CustomBarChart';
import { useBlocState } from '@/app/hooks';
import { Month, PlaysByMonth, monthNumberToName } from '@/app/utils/MonthNumberToName';
import { InputTextAutocomplete } from '@/app/ui/molecules';
import CustomLineChart, { LineChartData } from '@/app/ui/organisms/CustomLineChart/CustomLineChart';
import { stringToInt } from '@/app/utils/StringToInt';

const year: PlaysByMonth = {
  Jan: 0,
  Feb: 0,
  Mar: 0,
  Apr: 0,
  May: 0,
  Jun: 0,
  Jul: 0,
  Aug: 0,
  Sept: 0,
  Oct: 0,
  Nov: 0,
  Dec: 0,
};

export type UsageData = {
  [key: string]: PlaysByMonth;
};

const UsageTrend = () => {
  const { digitalDistributionBloc } = useCradle<Cradle>();
  const digitalDistributionState = useBlocState(digitalDistributionBloc);
  const [usagesData, setUsagesData] = useState<LineChartData>({});
  const [currentGroupFilter, setCurrentGroupFilter] = useState<DigitalPlatformId | undefined>();

  const { consumptionReportsBloc } = useCradle<Cradle>();

  const digitalPlatforms =
    digitalDistributionState.kind === 'LoadedDigitalDistributionState'
      ? digitalDistributionState.platforms
      : [];

  useEffect(() => {
    async function getTopSongsData() {
      const usagesFiltered = await consumptionReportsBloc.getUsages({
        digitalPlatformId: currentGroupFilter,
      });

      const usages: UsageData = {};
      usagesFiltered.data.forEach((usage) => {
        const month = monthNumberToName(usage.date.getMonth());
        if (usages[usage.date.getFullYear()] === undefined) {
          const newYear = { ...year };
          newYear[month] = usage.plays;
          usages[usage.date.getFullYear().toString()] = newYear;
        }
        usages[usage.date.getFullYear().toString()][month] += usage.plays;
      });

      const usagesBarData: LineChartData = {};
      Object.keys(usages).forEach((y: string) => {
        const playsYear: BarData[] = [];
        Object.keys(usages[y]).forEach((month: string) => {
          playsYear.push({
            x: month,
            y: usages[y][month as Month],
          });
        });
        usagesBarData[y] = playsYear;
      });

      setUsagesData(usagesBarData);
    }
    getTopSongsData();
  }, [currentGroupFilter]);

  return (
    <Flex.Container flexDirection="column" className="mt-5" gap="2.5rem">
      <Flex.Container style={{ width: '20%' }} gap="1rem">
        <InputTextAutocomplete
          placeholder="Platform..."
          items={[{ value: 'All', label: 'All' }].concat(
            digitalPlatforms.map((p) => ({ value: p.id.toString(), label: p.name })),
          )}
          onChange={(groupFilter) =>
            groupFilter === 'All'
              ? setCurrentGroupFilter(undefined)
              : setCurrentGroupFilter(stringToInt(groupFilter))
          }
        />
      </Flex.Container>
      <CustomLineChart yaxis="Plays" data={usagesData} />
    </Flex.Container>
  );
};

export default UsageTrend;
